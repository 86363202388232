import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const indexApi = API_URL?.indexOf('api')
const ASSETS_URL = process.env.REACT_APP_ASSETS_URL
export const BASE_URL = ASSETS_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const LOGOUT_URL = `${API_URL}/logout`
export const LOCATION_URL = `${API_URL}/location`
export const FORGOT_PASSWORD_URL = `${API_URL}/forgot-password`


// notification
export const POST_FCM_TOKEN = `${API_URL}/fcm-token`
export const GET_LIST_NOTIF_URL = `${API_URL}/notification/list`

// dashboard
export const GET_SUMMARY_DASHBOARD_URL = `${API_URL}/summary-request`
export const GET_SCHEDULE_DASHBOARD_URL = `${API_URL}/schedules-company`

// profile
export const GET_MY_PROFILE_URL = `${API_URL}/profile`
export const UPDATE_MY_PROFILE_URL = `${API_URL}/profile/update`
export const UPDATE_MY_PASSWORD_URL = `${API_URL}/user/update-password`

// attendess
export const GET_ATTENDEES_URL = `${API_URL}/participant`
export const GET_SLOT_SCHEDULE_URL = `${API_URL}/slotschedule`
export const SUBMIT_MEETING_SCHEDULE_URL = `${API_URL}/setmeeting`
export const UPDATE_STATUS_MEET_URL = `${API_URL}/update-request`

// schedules
export const GET_DETAIL_SCHEDULE_URL = `${API_URL}/appoiment-schedule`


// products
export const GET_PRODUCT_URL = `${API_URL}/product-list`
export const UPLOAD_PRODUCT_URL = `${API_URL}/product-add`
export const DELETE_PRODUCT_URL = `${API_URL}/product-delete`

// admin
export const GET_REPORT_URL = `${API_URL}/report-participant`
export const EXPORT_REPORT_URL = `${API_URL}/report-export`


// Firebase notification
export function postFcmToken(token: string) {
  return axios.post(POST_FCM_TOKEN, { fcm_token: token })
}

export function getNotificationFromDb() {
  return axios.get(GET_LIST_NOTIF_URL)
}

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(payload) {
  return axios.post(REGISTER_URL, payload, {
    headers: { "Content-Type": "multipart/form-data" }
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function logoutByToken(token: string) {
  return axios.get(LOGOUT_URL, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}

export function forgotPassword(email: string) {
  return axios.post(FORGOT_PASSWORD_URL, {
    email
  })
}

export function getLocation() {
  return axios.get(LOCATION_URL)
}


// API DASHBOOARD
export function getSummaryDashboard(companyId: number | null = null) {
  return axios.get(`${GET_SUMMARY_DASHBOARD_URL}${companyId ? '?companyd_id=' + companyId : ''}`);
}

export function getScheduleDashboard() {
  return axios.get(`${GET_SCHEDULE_DASHBOARD_URL}`);
}

// API PROFILE
export function getMyProfile() {
  return axios.get(`${GET_MY_PROFILE_URL}`)
}

export function postUpdateMyProfile(payload) {
  return axios.post(`${UPDATE_MY_PROFILE_URL}`, payload, {
    headers: { "Content-Type": "multipart/form-data" }
  })
}

export function postUpdateMyPassword(payload) {
  return axios.post(`${UPDATE_MY_PASSWORD_URL}`, payload)
}

// API ATTENDEES
export function getAttendes(search: string) {
  return axios.get(`${GET_ATTENDEES_URL}?search=${search}`)
}

export function getSlotSchedule(companyId) {
  return axios.get(`${GET_SLOT_SCHEDULE_URL}?participant_id=${companyId}`)
}

export function getDetailAttendee(id) {
  return axios.get(`${GET_ATTENDEES_URL}/${id}`)
}

export function postSubmitMeeting(payload) {
  return axios.post(`${SUBMIT_MEETING_SCHEDULE_URL}`, payload)
}

export function postCancelMeeting(id) {
  return axios.post(`${SUBMIT_MEETING_SCHEDULE_URL}/${id}`)
}

export function postStatusMeeting(payload) {
  return axios.post(`${UPDATE_STATUS_MEET_URL}`, payload)
}

// API DETAIL SCHEDULE
export function getDetailScheduleByCompany() {
  return axios.get(`${GET_DETAIL_SCHEDULE_URL}`)
}

// API PRODUCTS
export function getProducts(companyId: string) {
  return axios.get(`${GET_PRODUCT_URL}?participant_id=${companyId}`)
}

export function deleteProductsById(companyId: string) {
  return axios.post(`${DELETE_PRODUCT_URL}?product_id=${companyId}`)
}

// API GET REPORT
export function getReport(paramas) {
  return axios.get(`${GET_REPORT_URL}?${paramas}`)
}

export function postExportReport(params) {
  return axios({
    url: EXPORT_REPORT_URL,
    method: 'GET',
    responseType: 'blob',
    params: {
      ...params
    }
  })
}
