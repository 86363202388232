import { MouseEventHandler } from "react";
import { RiLoader4Fill } from "react-icons/ri";

type Props = {
  className: string
  description: string
  color: string
  img: string,
  labelColor?: string
  summaryData?: {
    confirmed_meetings: string,
    declined_request: string,
    getting_request: string,
    pending: string,
    send_request: string
  },
  participantData?: {
    confirmed_meetings?: Array<object>,
    declined_request?: Array<object>,
    getting_request?: Array<object>,
    pending?: Array<object>,
    send_request?: Array<object>
  },
  onClickModal?: MouseEventHandler,
  onClickRefresh?: MouseEventHandler,
  isLoading?: boolean
}

const CardsWidget20 = ({ isLoading = false, summaryData, participantData, className, description, color, img, labelColor, onClickModal, onClickRefresh }: Props) => {
  const getPercentage = () => {
    const total: number = parseInt(summaryData ? summaryData.getting_request : '0')
    const pending: number = parseInt(summaryData ? summaryData.pending : '0')
    const percent: number = pending === 0 ? 100 : parseInt(((pending / total) * 100).toFixed(0))
    return isNaN(percent) ? 0 : percent;
  }
  return (
    <div
      id="getingRequestCard"
      className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
      style={{
        backgroundColor: color,
        backgroundImage: `url('${img}')`,
      }}
    >
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{parseInt(summaryData?.getting_request || '0')}</span>

          <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>{description}</span>
        </div>
        {isLoading && (
          <span className='indicator-progress' style={{ display: 'block' }}>
            <span className='spinner-border spinner-border-sm align-middle ms-2' style={{ borderColor: '#8c80a9', borderRightColor: 'transparent' }}></span>
          </span>
        )}

        {!isLoading && (
          <span onClick={onClickRefresh} className={`menu-icon fs-2 ${labelColor === 'dark' ? 'text-white' : 'text-dark'}`}>
            <RiLoader4Fill className='' />
          </span>)}
      </div>
      <div className='card-body d-flex align-items-end pt-0' onClick={onClickModal}>
        <div className='d-flex align-items-center flex-column mt-3 w-100'>
          <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
            <span>{summaryData?.pending} Pending</span>
            <span>
              {getPercentage()} %
            </span>
          </div>

          <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
            <div
              className='bg-white rounded h-8px'
              role='progressbar'
              style={{ width: getPercentage() + '%' }}
              aria-valuenow={50}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export { CardsWidget20 }
