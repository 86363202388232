/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { RiLoader4Fill } from "react-icons/ri";
import { MouseEventHandler } from 'react';
import { createProfileImage, getInitialName } from '../../../../helpers';

type Props = {
  participants: Array<{
    id: number | null,
    company_name: string,
    company_type: string,
    contact_person: string,
    status_id?: number | null
  }>,
  idCard?: string,
  className: string
  description: string
  icon: boolean
  stats: number
  labelColor: string
  textColor: string,
  color?: string,
  isLoading?: boolean,
  onClickModal?: MouseEventHandler,
  onClickRefresh?: MouseEventHandler
}

const CardsWidget7 = ({ idCard = 'card-board', isLoading = false, participants, className, description, icon, stats, labelColor, textColor, color, onClickModal, onClickRefresh }: Props) => {
  return (
    <div id={idCard} className={`card card-flush ${className}`}
      style={{
        backgroundColor: color,
        border: '1px solid #e0e0e0'
      }}
    >
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-row w-100 justify-content-between align-items-start'>
          <div className='card-title d-flex flex-column'>
            <span className={`fs-2hx fw-bold ${labelColor === 'light' ? 'text-dark' : 'text-white'} me-2 lh-1 ls-n2`}>{stats}</span>
            <span className={`${labelColor === 'light' ? 'text-gray-400' : 'text-white opacity-75'} pt-1 fw-semibold fs-6`}>{description}</span>
          </div>
          {isLoading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}

          {!isLoading && (
            <span onClick={onClickRefresh} className={`menu-icon fs-2 ${labelColor === 'dark' ? 'text-white' : 'text-dark'}`}>
              <RiLoader4Fill className='' />
            </span>)}
        </div>
      </div>
      <div className='card-body d-flex flex-column justify-content-end pe-0' onClick={onClickModal}>
        <span className={`${labelColor === 'light' ? 'text-gray-800' : 'text-white opacity-75'} fs-6 fw-bolder d-block mb-2`}>List Company</span>
        <div className='symbol-group symbol-hover flex-nowrap'>
          {participants.slice(0, 3).map((item, index) => (
            <>
              { createProfileImage('35px', item) }
            </>
          ))}

          <a href='#' className={`${participants.length > 3 ? 'd-flex' : 'd-none'} symbol symbol-35px symbol-circle`}>
            <span
              className={clsx('symbol-label fs-8 fw-bold', 'bg-dark', 'text-' + textColor)}
            >
              + {participants.length - 3}
            </span>
          </a>
        </div>
        <span
          className={`${participants.length ? 'd-none' : 'd-flex'} ${clsx('fs-6 fw-bold', 'text-gray-600')}`}
        >Not Available</span>
      </div>
    </div>
  )
}
export { CardsWidget7 }
