import { FC, useEffect, useRef, useState } from "react";
import { RiSearchLine, RiCalendarLine, RiCloseLine, RiCheckLine } from "react-icons/ri";
import { PageTitle } from "../../../_metronic/layout/core";
import FsLightbox from "fslightbox-react";
import { getAttendes, getDetailAttendee, getSlotSchedule, postSubmitMeeting, postCancelMeeting, postStatusMeeting, BASE_URL } from "../../modules/auth/core/_requests";
import { Modal, ModalBody } from "react-bootstrap";
import { useAuth } from "../../modules/auth";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { createProfileImage, extractTime, getInitialName } from "../../../_metronic/helpers";
import Moment from 'react-moment';
import 'moment-timezone';
import { useLocation, useNavigate } from "react-router-dom";

const AttendeesPage: FC = () => {
    const active = {
        borderBottom: '2px solid #3F98FF',
        fontWeight: 700,
        fontSize: '1.05rem'
    }

    const MySwal = withReactContent(Swal)

    // const timeStart = 7;
    // const timeEnd = 18;
    // let arrayTime: {
    //     time: string,
    //     type: string,
    //     available: boolean
    // }[] = [];

    // for (let i = timeStart; i <= timeEnd; i++) {
    //     arrayTime.push({
    //         time: `${i < 10 ? '0' + i : i}:00`,
    //         type: i > 12 ? 'pm' : 'am',
    //         available: true
    //     }, {
    //         time: `${i < 10 ? '0' + i : i}:30`,
    //         type: i > 12 ? 'pm' : 'am',
    //         available: true
    //     })
    // }

    // let now = new Date()
    // let mapTimeToDate: {
    //     date: string,
    //     detail: {
    //         time: string,
    //         type: string,
    //         available: boolean
    //     }[]
    // }[] = []

    // for (let i = 0; i < 3; i++) {
    //     now = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)
    //     mapTimeToDate.push({
    //         date: `${now.getFullYear()}/${now.getMonth()}/${now.getDate()}`,
    //         detail: arrayTime
    //     })
    // }

    // const schedules = {
    //     company_id: 301,
    //     partner_id: 401,
    //     schedules: mapTimeToDate
    // }

    const [keywordSearch, setKeywordSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [toggler, setToggler] = useState(false);
    const [togglerIndex, setTogglerIndex] = useState<number>(0);
    const [activeTab, setActiveTab] = useState('about')
    const [activeDate, setActiveDate] = useState<{
        time: string,
        date: string,
        slot_id: number | null,
        schedule_id: number | null
    }>({
        slot_id: null,
        date: '',
        time: '',
        schedule_id: null
    })

    const [selectLoading, setSelectLoading] = useState(false);
    const [showSetMeeting, setShowSetMeeting] = useState(false);
    const [showCancelMeeting, setShowCancelMeeting] = useState(false);

    const [schedules, setSchedules] = useState<Array<{
        date: string,
        participant_id: number | null,
        schedule_id: number | null,
        detail: Array<{
            time: string,
            type: string,
            is_available: number,
            slot_id: number | null
        }>
    }>>([{
        date: '',
        participant_id: null,
        schedule_id: null,
        detail: [
            {
                time: '',
                type: '',
                is_available: 0,
                slot_id: null
            }
        ]
    }]);


    const [loadingScedule, setLoadingSchedule] = useState(false)
    const setMeeting = async (id) => {
        setShowSetMeeting(true);
        setLoadingSchedule(true)
        setSchedules([])
        setActiveDate({
            slot_id: null,
            date: '',
            time: '',
            schedule_id: null
        })

        try {
            const response = await getSlotSchedule(id)
            setSchedules(response.data)
        } catch (error) {
            MySwal.fire({
                title: 'Error',
                html: "Failed get schedule data",
                icon: 'error'
            })
        } finally {
            setLoadingSchedule(false)
        }

        // setTimeout(() => {
        //     setSchedules([
        //         {
        //             "participant_id": 33,
        //             "schedule_id": 1,
        //             "date": "2023-06-23 07:16:36",
        //             "detail": [
        //                 {
        //                     "slot_id": 1,
        //                     "time": "07:00:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 },
        //                 {
        //                     "slot_id": 2,
        //                     "time": "07:30:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 },
        //                 {
        //                     "slot_id": 3,
        //                     "time": "08:00:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 },
        //                 {
        //                     "slot_id": 4,
        //                     "time": "08:30:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 },
        //                 {
        //                     "slot_id": 5,
        //                     "time": "09:00:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 },
        //                 {
        //                     "slot_id": 6,
        //                     "time": "09:30:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 }
        //             ]
        //         },
        //         {
        //             "participant_id": 33,
        //             "schedule_id": 2,
        //             "date": "2023-06-24 07:16:36",
        //             "detail": [
        //                 {
        //                     "slot_id": 7,
        //                     "time": "07:00:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 },
        //                 {
        //                     "slot_id": 8,
        //                     "time": "07:30:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 },
        //                 {
        //                     "slot_id": 9,
        //                     "time": "08:00:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 },
        //                 {
        //                     "slot_id": 10,
        //                     "time": "08:30:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 },
        //                 {
        //                     "slot_id": 11,
        //                     "time": "09:00:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 },
        //                 {
        //                     "slot_id": 12,
        //                     "time": "09:00:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 }
        //             ]
        //         },

        //     ])
        // }, 1000);

        // console.log(response.data)
    }

    type IAttendees = {
        id: number | null,
        company_name: string,
        company_type: string,
        company_type_name: string,
        country: {
            name: string
        },
        province_name: string,
        profile_image?: string
    }

    const [attendees, setAttendees] = useState<Array<IAttendees>>([]);
    // const [selectedAttendee, setSelectedAttendee] = useState(null);
    const selectedAttendee = useRef<number | null>(null);
    const [detailAttendee, setDetailAtendee] = useState<{
        id: number | null,
        detail_schedule_id: number | null,
        type: string,
        time: string,
        schedule_date: string,
        end_of_time: string,
        datetime: string,
        company_name: string,
        company_type: string,
        company_type_name: string,
        contact_person: string,
        country: {
            name: string
        },
        district: string,
        company_website: string,
        description: string,
        has_appointment: boolean | number,
        request_owner_id: number,
        profile_image?: string,
        status_label: string,
        product: Array<{
            id: number | null,
            name_product: string,
            path_file: string
        }>,
        product_view: Array<string>
    }>({
        id: null,
        detail_schedule_id: null,
        type: '',
        time: '',
        schedule_date: '',
        end_of_time: '',
        datetime: '',
        company_name: '',
        company_type: '',
        company_type_name: '',
        contact_person: '',
        country: {
            name: '-'
        },
        district: '',
        company_website: '',
        description: '',
        has_appointment: 0,
        request_owner_id: 0,
        status_label: '',
        product: [{
            id: null,
            name_product: '',
            path_file: ''
        }],
        product_view: [],
        profile_image: ''
    })

    const fetchDetailAttendee = async function () {
        try {
            setSelectLoading(true)
            const response = await getDetailAttendee(selectedAttendee.current);
            response.data.product_view = getResourceImage(response.data.product)
            setActiveTab('about')
            tabRef?.current?.scrollIntoView({ behavior: 'smooth' });
            setDetailAtendee(response.data);
        } catch (error) {
            MySwal.fire({
                title: 'Error',
                html: "Failed get detail attendee data",
                icon: 'error'
            })
        } finally {
            setSelectLoading(false)
        }

        // await setTimeout(() => {
        //     setDetailAtendee({
        //         "id": 33,
        //         "detail_schedule_id": 1,
        //         "type": "Importer",
        //         "time": "10:30",
        //         "end_of_time": "11:00",
        //         "schedule_date": "2023-0-16",
        //         "company_name": "Tri Warsono",
        //         "country": "Indonesia",
        //         "district": "Jakarta",
        //         "contact_person": "123",
        //         "company_type": "1",
        //         "company_type_name": "Importers",
        //         "company_website": "test.com",
        //         "description": "test aja",
        //         "has_appointment": 2,
        //         "product": [
        //             {
        //                 "id": 19,
        //                 "name_product": "168514642973.png",
        //                 "path_file": "/media/books/1.png",
        //             },
        //             {
        //                 "id": 20,
        //                 "name_product": "168514642948.png",
        //                 "path_file": "/media/books/2.png",
        //             }
        //         ]
        //     })
        // })
    }

    const tabRef = useRef<any>(null);

    const stateParam = useLocation();
    const navigate = useNavigate();

    const urlParam: any = stateParam.state

    const params: {
        participant_id: number,
        status_id: number,
        name: string,
        detail_schedule_id: number
    } = {
        participant_id: urlParam?.participant_id,
        status_id: urlParam?.status_id,
        name: urlParam?.name,
        detail_schedule_id: urlParam?.detail_schedule_id
    }

    const selectAttendee = (id) => {
        selectedAttendee.current = id;
        fetchDetailAttendee();
    }


    const getResourceImage = (product) => {
        return product.reduce((temp, item) => { return [...temp, `${item.path_file}`] }, []);
    }

    const { auth } = useAuth()
    const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(false);

    const submitMeeting = async () => {
        let payload = {
            ...activeDate,
            participant_id: selectedAttendee.current,
            company_id: auth?.user.participant.id
        }

        if (!payload.schedule_id) {
            MySwal.fire({
                title: 'Attention',
                html: 'Please choose a schedule first',
                icon: 'warning'
            })

            return;
        }

        try {
            setIsSubmitBtnDisabled(true)
            const response = await postSubmitMeeting(payload)
            if (response.data.status) {
                MySwal.fire({
                    title: 'Success',
                    html: response.data.message,
                    icon: 'success'
                })
                setDetailAtendee({
                    ...detailAttendee,
                    detail_schedule_id: response.data.data.id,
                    has_appointment: 1
                })
                fetchDetailAttendee()
            } else {
                throw new Error("Not Found");
            }
        } catch (error) {
            MySwal.fire({
                title: 'Error',
                html: "error",
                icon: 'error'
            })
        } finally {
            setIsSubmitBtnDisabled(false)
        }

        setShowSetMeeting(false)
    }

    const [isCancelBtnDisabled, setIsCancelBtnDisabled] = useState(false)
    const cancelMeeting = async () => {
        let payload = {
            detail_schedule_id: detailAttendee.detail_schedule_id,
            status_id: 4
        }

        try {
            setIsCancelBtnDisabled(true)
            const response = await postStatusMeeting(payload)
            if (response.data.status) {
                MySwal.fire({
                    title: 'Success',
                    html: response.data.message,
                    icon: 'success'
                })
                setDetailAtendee({ ...detailAttendee, has_appointment: 0 })
                fetchDetailAttendee();
            } else {
                throw new Error("Not Found");
            }
        } catch (error) {
            MySwal.fire({
                title: 'Error',
                html: "error",
                icon: 'error'
            })
        } finally {
            setIsCancelBtnDisabled(false)
        }
        setShowCancelMeeting(false)
    }


    useEffect(() => {
        // console.log('param', participant_id, status_id)
        async function fetchAttendees() {
            setLoading(true)
            setAttendees([]);
            try {
                const hasParams: any = stateParam.state

                let attendees: { data: { participants: Array<IAttendees> } } = { data: { participants: [] } }

                let excludeUser: Array<IAttendees> = []

                if (hasParams && hasParams.hasOwnProperty('participant_id')) {
                    attendees = await getAttendes(hasParams.name);
                    excludeUser = attendees.data.participants
                    selectAttendee(hasParams.participant_id)
                } else {
                    attendees = await getAttendes(keywordSearch);
                    excludeUser = attendees.data.participants.filter(item => item.id !== auth?.user.participant.id)
                }

                setAttendees(excludeUser);
                navigate(stateParam.pathname, {}); 
            } catch (error) {
                console.log('error', error)
                MySwal.fire({
                    title: 'Error',
                    html: "Failed get attendees data",
                    icon: 'error'
                })

            }
            setLoading(false);
        }

        const delayDebounceFn = setTimeout(() => {
            fetchAttendees()
        }, keywordSearch ? 2000 : 0)

        return () => clearTimeout(delayDebounceFn)
    }, [keywordSearch])

    const [detailMeeting, setDetailMeeting] = useState<{
        timeStart: string,
        timeEnd: string,
        company_name: string
    }>({
        timeStart: '',
        timeEnd: '',
        company_name: ''
    })

    function openCancelMeeting(data) {
        const extract = extractTime(data.time, data.end_of_time, data.schedule_date);
        const selectedDate = {
            timeStart: extract.timeStartWith24,
            timeEnd: extract.timeEndWith24,
            company_name: data.company_name
        }

        setDetailMeeting(selectedDate);
        setShowCancelMeeting(true)
    }

    // handle redirect from dashboard reuest

    const [loadingUpdate, setLoadingUpdate] = useState(false);
    
    async function updateRequest(detail_schedule_id, status_id) {
        let payload = {
            detail_schedule_id: detail_schedule_id,
            status_id: status_id
        }

        setLoadingUpdate(true);

        try {
            const response = await postStatusMeeting(payload)
            fetchDetailAttendee();
            if (response.data.status) {
                MySwal.fire({
                    title: 'Success',
                    html: response.data.message,
                    icon: 'success'
                })
            } else {
                new Error("Error");
            }
        } catch (error) {
            MySwal.fire({
                title: 'Error',
                html: "error",
                icon: 'error'
            })
        } finally {
            setLoadingUpdate(false);
        }
    }

    return (
        <>
            {/* begin::Attendees List */}
            <div className='row'>
                <div className='col-xxl-6'>
                    <div className='card card-xl-stretch mb-xl-8'>
                        {/* begin::Header */}
                        <div className='card-header border-0 pt-5'>
                            <div className='card-title text-center d-block w-100'>
                                <div className='d-flex align-items-center position-relative my-1'>
                                    <div className='fs-3 position-absolute ms-4 text-gray-600'>
                                        <RiSearchLine />
                                    </div>
                                    <input
                                        type='text'
                                        data-kt-user-table-filter='search'
                                        className='form-control form-control-solid ps-14'
                                        placeholder='Search attendees'
                                        value={keywordSearch}
                                        onChange={(e) => setKeywordSearch(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* end::Header */}
                        {/* begin::Body */}
                        <div className='card-body pt-5'>
                            {loading && (
                                <div className='d-flex justify-content-center'>
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                </div>
                            )}
                            {/* begin::Item */}
                            <div className="scroll scroll-pull" data-scroll="true" data-wheel-propagation="true" style={{ height: '430px' }}>
                                {attendees.length > 0 ? attendees.map((item, index) => {
                                    return (
                                        <div key={index} className='d-flex attendee-item align-items-sm-center p-3 rounded' onClick={() => selectAttendee(item.id)} style={{ backgroundColor: item.id === selectedAttendee.current ? '#e7f4ff' : '' }}>
                                            {createProfileImage('40px', item)}
                                            <div className='d-flex align-items-center flex-row-fluid flex-wrap ps-4'>
                                                <div className='flex-grow-1 me-2'>
                                                    <span className='text-gray-800 text-hover-primary fs-5 fw-bold'>
                                                        {item.company_name}
                                                    </span>
                                                    {/* {
                                                        item.company_type_name && (!item.province_name || item.province_name === '-') && (
                                                            <span className='text-muted fw-bold d-block fs-7'>{item.company_type_name}</span>
                                                        )
                                                    }
                                                    
                                                    {
                                                        (item.company_type_name && item.company_type_name !== '-') && (item.province_name && item.province_name !== '-') && (
                                                            <span className='text-muted fw-bold d-block fs-7'>{item.company_type_name} | {item.province_name}, {item.country.name}</span>
                                                        )
                                                    } 

                                                    {
                                                        (item.company_type_name && item.company_type_name !== '-') && (item.province_name && item.province_name !== '-') && (
                                                            <span className='text-muted fw-bold d-block fs-7'>{item.company_type_name} | {item.province_name}, {item.country.name}</span>
                                                        )
                                                    } 
                                                    
                                                    */}

                                                    {
                                                        (item.company_type_name && item.company_type_name !== '-') && (item.country.name && item.country.name !== '-') && (
                                                            <span className='text-muted fw-bold d-block fs-7'>{item.country.name}</span>
                                                        )
                                                    }
                                                </div>
                                                {selectLoading && item.id === selectedAttendee.current &&
                                                    <div className='d-flex justify-content-center'>
                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                                            Please wait...
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                }) : <div className={`${loading ? 'd-none' : ''} w-100 text-center`}>Data not found</div>}
                            </div>
                            {/* end::Item */}
                        </div>
                    </div>
                </div>

                <div className='col-xxl-6 mt-8 mt-xl-0'>
                    <div className='card card-xl-stretch mb-xl-8'>
                        <div className='card-header justify-content-start align-items-center border-0 pt-5'>
                            <div className='card-title fs-2'>
                                <RiCalendarLine />
                            </div>
                            <h3 className='card-label fw-bold text-dark mt-3'>Company Details</h3>
                        </div>

                        {/* begin::Body */}
                        <div className={`card-body pt-5 ${selectedAttendee.current == null ? 'd-none' : 'd-block'}`}>
                            <div id="detailAttendee">
                                <div className='d-flex align-items-sm-center'>
                                    <span onClick={() => setMeeting(detailAttendee.id)} style={{ cursor: 'pointer' }} className={`badge badge-light ${detailAttendee.has_appointment === 0 ? 'visible' : 'd-none'} fw-bold my-2 p-4`}>Request Meeting</span>
                                    {(detailAttendee.has_appointment === 1 && detailAttendee.request_owner_id === auth?.user.participant.id) && (
                                        <span onClick={() => openCancelMeeting(detailAttendee)} style={{ cursor: 'pointer' }} className={`badge badge-danger-ttc ${detailAttendee.has_appointment === 1 ? 'visible' : 'd-none'} fw-bold my-2 p-4`}>Cancel Request</span>
                                    )}
                                    {(detailAttendee.has_appointment === 1 && detailAttendee.request_owner_id !== auth?.user.participant.id) && (
                                        <div className='d-flex ps-3'>
                                            <div>
                                                <span className='text-gray-700 text-hover-primary fw-bold fs-7 d-block'>
                                                    Appoinment Meeting
                                                </span>
                                                <span className='fw-bold fs-5'>
                                                    {detailAttendee.datetime}
                                                    <span className='text-muted fw-bold fs-8'>&nbsp;</span>
                                                </span>
                                            </div>
                                            
                                            <div className="ps-5">
                                                { loadingUpdate ? (
                                                    <div className='d-flex justify-content-center'>
                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                                            Please wait...
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <span onClick={() => { updateRequest(detailAttendee.detail_schedule_id, 3); }} className='badge badge-light fs-2 fw-bold text-white my-2 p-2 ms-2 me-2' style={{ backgroundColor: '#F23F6C' }}>
                                                            <RiCloseLine />
                                                        </span>
                                                        <span onClick={() => { updateRequest(params.detail_schedule_id, 2); }} className='badge badge-light fs-2 fw-bold text-white my-2 p-2' style={{ backgroundColor: '#4199F8' }}>
                                                            <RiCheckLine />
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='d-flex align-items-sm-center p-3 rounded'>
                                    {createProfileImage('70px', detailAttendee)}
                                    <div className='d-flex align-items-center flex-row-fluid flex-wrap ps-4'>
                                        <div className='flex-grow-1 me-2'>
                                            <span className='text-gray-800 text-hover-primary fs-4 fw-bold'>
                                                {detailAttendee.company_name}
                                            </span>
                                            {
                                                (detailAttendee.company_type_name && detailAttendee.company_type_name !== '-') && (detailAttendee.country.name && detailAttendee.country.name !== '-') && (
                                                    <span className='text-muted fw-bold d-block fs-7'>{detailAttendee.country.name}</span>
                                                )
                                            }
                                            <span className='text-muted fw-bold d-block fs-7'>{detailAttendee.contact_person}</span>
                                            {/* <span className='text-muted fw-bold d-block fs-7'>{detailAttendee.company_type_name}</span> */}
                                        </div>
                                    </div>
                                </div>
                                <ul className="nav nav-line-tabs mb-10" id="pills-tab" role="tablist" ref={tabRef}>
                                    <li className="nav-item" role="presentation" onClick={() => setActiveTab('about')}>
                                        <span className="nav-link text-gray-400 fw-bold cursor-pointer" style={activeTab === 'about' ? active : {}} id="pills-home-tab" data-bs-toggle="tab" data-bs-target="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">About</span>
                                    </li>
                                    <li className="nav-item" role="presentation" onClick={() => setActiveTab('product')}>
                                        <span className="nav-link text-gray-400 fw-bold cursor-pointer" style={activeTab === 'product' ? active : {}} id="pills-profile-tab" data-bs-toggle="tab" data-bs-target="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Product</span>
                                    </li>
                                </ul>
                                <div className="tab-content mt-3" id="pills-tabContent">
                                    <div className={`tab-pane fade ${activeTab === 'about' ? 'show active' : ''}`} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                        {/* <div className='section-about d-flex flex-column'>
                                            <span className='fw-bold text-gray-400 fs-7'>Location</span>
                                            <span className='fw-bold fs-5'>{detailAttendee.country} - {detailAttendee.district}</span>
                                        </div> */}
                                        <div className='section-about d-flex flex-column mt-2'>
                                            <span className='fw-bold text-gray-400 fs-7'>Company Website</span>
                                            <span className='fw-bold fs-5'>{detailAttendee.company_website}</span>
                                        </div>
                                        <div className='section-about d-flex flex-column mt-2'>
                                            <span className='fw-bold text-gray-400 fs-7'>Description</span>
                                            <p className='fs-7 fw-semibold'>
                                                {detailAttendee.description}
                                            </p>
                                        </div>
                                    </div>
                                    <div className={`tab-pane fade ${activeTab === 'product' ? 'show active' : ''}`} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <div className="row g-10 row-cols-2 row-cols-lg-3">
                                            {Object(detailAttendee).hasOwnProperty('product') ? (detailAttendee.product.length > 0 ? detailAttendee.product.map((item, index) => {
                                                return (
                                                    <div key={index} className="col rounded" onClick={() => { setToggler(!toggler); setTogglerIndex(index) }}>
                                                        <div className="d-block overlay" data-fslightbox="lightbox-hot-sales">
                                                            <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px" style={{ backgroundImage: `url('${item.path_file}')` }}>
                                                            </div>
                                                            <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                                <i className="ki-outline ki-eye fs-3x text-white"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) : '') : ''}
                                        </div>
                                        {/* <FsLightbox
                                            toggler={toggler}
                                            sources={getResourceImage(detailAttendee.product || [])}
                                            sourceIndex={togglerIndex}
                                        onClose={() => setToggler(!toggler)}
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="emptyAttendee" className={`card-body pt-5 ${selectedAttendee.current == null ? 'd-block' : 'd-none'}`}>
                            <div className='text-center'>
                                <h3 className="fs-1 fw-bold">
                                    Welcome to the Atendees List
                                    <br />of Business Matching App
                                </h3>
                                <p className="text-gray-600 mt-3">
                                    It's time to expand your business.<br />
                                    Set your appointment meeting and experience the<br />
                                    benefits today!
                                </p>
                                <img src="/ttc/illustration.png" className='img-fluid w-50' alt="attendees list illustrator" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* end::Attendees List */}

            {/* begin::Set Meeting */}
            <Modal show={showSetMeeting} onHide={() => setShowSetMeeting(false)}>
                <Modal.Header closeButton>
                    <div className='card-title text-center d-block w-100'>
                        <div className='pb-3'>
                            {/* begin::Symbol */}
                            {createProfileImage('40px', detailAttendee)}
                            {/* end::Symbol */}
                        </div>
                        <span className='text-muted mt-1 fw-semibold fs-7'>Meet with {detailAttendee.company_name}</span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {/* begin::Item */}
                    <div className="scroll scroll-pull" data-scroll="true" data-wheel-propagation="true" style={{ height: '400px' }}>
                        {(loadingScedule) && (
                            <div className='d-flex justify-content-center'>
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            </div>
                        )}
                        <table id="setMeetingTable" className="table table-rounded border border-gray-300 gy-4 gs-5 text-center">
                            <thead className="sticky-top bg-white">
                                <tr className="fw-bold fs-4 text-gray-800">
                                    {schedules.length ? schedules.map((item, index) => {
                                        return (
                                            <th key={index} scope="col" className='col-3' style={{ width: '33%' }}>
                                                <Moment date={item.date} format="ddd DD/MM"></Moment>
                                            </th>
                                        )
                                    }) : ''}
                                </tr>
                            </thead>
                            <tbody>
                                {schedules.length ? schedules[0].detail.map((item, indexTime) => {
                                    return (
                                        <tr key={indexTime}>
                                            {schedules.length ? schedules.map((item, indexDate) => {
                                                const dt = schedules[indexDate]
                                                const isAvailable = dt.detail[indexTime].is_available
                                                // return ( <td className={ `${JSON.stringify(dt)}` }></td> )
                                                return dt.detail.length > 0 ? (
                                                    <td className={
                                                        `${activeDate.date === dt.date && activeDate.time === dt.detail[indexTime].time ? 'schedule-selected' : (isAvailable ? '' : 'schedule-disabled')}`
                                                    } onClick={isAvailable ? () => {
                                                        setActiveDate({
                                                            schedule_id: dt.schedule_id,
                                                            slot_id: dt.detail[indexTime].slot_id,
                                                            date: dt.date,
                                                            time: dt.detail[indexTime].time
                                                        })
                                                    } : () => { }}
                                                        key={`${indexTime}${indexDate}`}
                                                    >{dt.detail[indexTime].time.substring(0, 5)}</td>
                                                ) : (<td>-</td>)
                                            }) : <td></td>}
                                        </tr>
                                    )
                                }) : <tr></tr>}
                            </tbody>
                        </table>
                    </div>
                    {/* end::Item */}
                    <div className="d-flex w-100 justify-content-center">
                        <button disabled={isSubmitBtnDisabled} className="btn btn-sm btn-primary" onClick={() => submitMeeting()}>
                            {(isSubmitBtnDisabled) && (
                                <div className='d-flex justify-content-center'>
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                </div>
                            )}

                            {!isSubmitBtnDisabled && (<span>SUBMIT</span>)}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            {/* end::Set meeting */}

            {/* begin:Cancel Meeting */}
            <Modal show={showCancelMeeting} onHide={() => setShowCancelMeeting(false)}>
                <Modal.Header className="align-items-start" closeButton>
                    <div className='card-title fs-2'>
                        <RiCalendarLine />
                    </div>
                    <div className='card-title flex-column ms-5 mt-1'>
                        <h3 className='card-label fw-bold text-dark'>Cancel meeting</h3>
                        <span className='text-muted fw-semibold fs-7'>With {detailMeeting.company_name}</span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {/* begin::Item */}
                    <div className="cancel-content ms-10">
                        <div className='d-flex align-items-center'>
                            <div className="symbol bg-success symbol-circle me-2" style={{ height: '10px', width: '10px' }}></div>
                            <h4 className='mt-2 fw-bold fs-6'>
                                Starts&nbsp;
                                <span className='text-muted mt-1 fw-semibold fs-7'>{detailMeeting.timeStart}</span>
                            </h4>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className="symbol bg-danger symbol-circle me-2" style={{ height: '10px', width: '10px' }}></div>
                            <h4 className='mt-2 fw-bold fs-6'>
                                Ends&nbsp;
                                <span className='text-muted mt-1 fw-semibold fs-7'>{detailMeeting.timeEnd}</span>
                            </h4>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center mt-10'>
                        <button disabled={isCancelBtnDisabled} className='btn btn-sm btn-primary' onClick={() => cancelMeeting()}>
                            {(isCancelBtnDisabled) && (
                                <div className='d-flex justify-content-center'>
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                </div>
                            )}

                            {!isCancelBtnDisabled && (<span>PROCESS</span>)}
                        </button>
                    </div>
                    {/* end::Item */}
                </Modal.Body>
            </Modal>
            {/* end:Cancel Meeting */}

            {/* begin:Preview Image */}
            <Modal show={toggler} onHide={() => setToggler(!toggler)}>
                <Modal.Body>
                    <div className="position-relative">
                        <div className="d-flex w-100 justify-content-end position-absolute">
                            <div className="symbol symbol-30px symbol-circle" data-bs-toggle="tooltip">
                                <span className="symbol-label fw-bold bg-secondary text-inverse-warning">
                                    <span className="fs-1" onClick={() => setToggler(!toggler)}><RiCloseLine /></span>
                                </span>
                            </div>
                        </div>
                        {/* begin::Item */}
                        <div className="d-flex w-100 justify-content-center align-items-center">
                            <img className="img-fluid" alt="preview" src={detailAttendee.product ? detailAttendee.product_view[togglerIndex] : ''} />
                        </div>
                    </div>
                    {/* end::Item */}
                </Modal.Body>
            </Modal>
            {/* end:Preview Image */}
        </>
    )
}

const AttendeesWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Attendees</PageTitle>
            <AttendeesPage />
        </>
    )
}

export { AttendeesWrapper };