import { FC, useEffect, useRef, useState } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { RiCalendarLine, RiSearchLine, RiCloseLine } from "react-icons/ri";
import FsLightbox from "fslightbox-react";
import { CardsWidget20, CardsWidget7 } from "../../../_metronic/partials/widgets";
import { getSummaryDashboard, getDetailAttendee, getAttendes } from "../../modules/auth/core/_requests";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import SendingRequestDsb from "../../modules/auth/components/SendingRequestDsb";
import GettingRequestDsb from "../../modules/auth/components/GettingRequestDsb";
import { useAuth } from "../../modules/auth";
import { Modal } from "react-bootstrap";
import { createProfileImage } from "../../../_metronic/helpers";

const AttendeesPage: FC = () => {
    const MySwal = withReactContent(Swal)

    const active = {
        borderBottom: '2px solid #3F98FF',
        fontWeight: 700,
        fontSize: '1.05rem'
    }

    type ItemRequest = {
        id: number | null,
        company_name: string,
        company_type: string,
        company_type_name: string,
        contact_person: string,
        status_id?: number | null
    }

    const { auth } = useAuth()
    const [loadingSummary, setLoadingSummary] = useState(false);
    const [keywordSearch, setKeywordSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [toggler, setToggler] = useState(false);
    const [togglerIndex, setTogglerIndex] = useState<number>(0);
    const [activeTab, setActiveTab] = useState('about')

    const [participants, setParticipants] = useState<{
        confirmed_meetings: Array<ItemRequest>,
        declined_request: Array<ItemRequest>,
        getting_request: Array<ItemRequest>,
        pending: Array<ItemRequest>,
        send_request: Array<ItemRequest>
    }>({
        confirmed_meetings: [],
        declined_request: [],
        getting_request: [],
        pending: [],
        send_request: []
    })

    const [summarry, setSummary] = useState<{
        confirmed_meetings: string,
        declined_request: string,
        getting_request: string,
        pending: string,
        send_request: string
    }>({
        confirmed_meetings: "",
        declined_request: "",
        getting_request: "",
        pending: "",
        send_request: ""
    })


    const [attendees, setAttendees] = useState<Array<{
        id: number | null,
        company_name: string,
        company_type: string
        company_type_name: string,
        province_name: string,
        country: string,
        profile_image?: string
    }>>([]);

    const selectedAttendee = useRef<number | null>(null);
    const [detailAttendee, setDetailAtendee] = useState<{
        id: number | null,
        company_name: string,
        company_type: string,
        company_type_name: string,
        contact_person: string,
        country: string,
        district: string,
        company_website: string,
        description: string,
        has_appointment: boolean,
        product: Array<{
            id: number | null,
            name_product: string,
            path_file: string
        }>
        product_view: Array<string>,
        profile_image?: string
    }>({
        id: null,
        company_name: '',
        company_type: '',
        company_type_name: '',
        contact_person: '',
        country: '',
        district: '',
        company_website: '',
        description: '',
        has_appointment: false,
        product: [{
            id: null,
            name_product: '',
            path_file: ''
        }],
        product_view: [],
        profile_image: '',
    })

    async function fetchSummary() {
        try {
            setLoadingSummary(true)
            const response = await getSummaryDashboard(selectedAttendee.current)
            setParticipants(response.data.details)
            setSummary(response.data.counts)
        } catch (error) {
            MySwal.fire({
                title: 'Error',
                html: "Can't fetch data",
                icon: 'error'
            })
        } finally {
            setLoadingSummary(false)
        }

    }

    const fetchDetailAttendee = async function () {
        try {
            setSelectLoading(true)
            fetchSummary()
            const response = await getDetailAttendee(selectedAttendee.current);
            response.data.product_view = getResourceImage(response.data.product)
            setDetailAtendee(response.data);
            setActiveTab('about')
            setSelectLoading(false)
        } catch (error) {
            MySwal.fire({
                title: 'Error',
                html: "Can't fetch data",
                icon: 'error'
            })
            setSelectLoading(false)
        }

        // await setTimeout(() => {
        //     setDetailAtendee({
        //         "id": 33,
        //         "company_name": "Tri Warsono",
        //         "country": "Indonesia",
        //         "district": "Jakarta",
        //         "contact_person": "123",
        //         "company_type": "Importers",
        //         "company_website": "test.com",
        //         "description": "test aja",
        //         "has_appointment": false,
        //         "product": [
        //             {
        //                 "id": 19,
        //                 "name_product": "168514642973.png",
        //                 "path_file": "/media/books/1.png",
        //             },
        //             {
        //                 "id": 20,
        //                 "name_product": "168514642948.png",
        //                 "path_file": "/media/books/2.png",
        //             }
        //         ]
        //     })

        //     const participants = {
        //         confirmed_meetings: [
        //             {
        //                 id: 1,
        //                 company_name: 'PT Confirmed 1',
        //                 company_type: 'IT',
        //                 contact_person: 'John Doe',
        //                 status_id: 2
        //             }
        //         ],
        //         declined_request: [
        //             {
        //                 id: 2,
        //                 company_name: 'PT Declined 1',
        //                 company_type: 'IT',
        //                 contact_person: 'John Doe',
        //                 status_id: 2
        //             }
        //         ],
        //         getting_request: [
        //             {
        //                 id: 3,
        //                 company_name: 'PT Getting 1',
        //                 company_type: 'IT',
        //                 contact_person: 'John Doe',
        //                 status_id: 2
        //             }
        //         ],
        //         pending: [
        //             {
        //                 id: 4,
        //                 company_name: 'PT Pending 1',
        //                 company_type: 'IT',
        //                 contact_person: 'John Doe',
        //                 status_id: 2
        //             }
        //         ],
        //         send_request: [
        //             {
        //                 id: 5,
        //                 company_name: 'PT Send 1',
        //                 company_type: 'IT',
        //                 contact_person: 'John Doe',
        //                 status_id: 2
        //             }
        //         ]
        //     }

        //     const summary = {
        //         confirmed_meetings: "3",
        //         declined_request: "2",
        //         getting_request: "3",
        //         pending: "1",
        //         send_request: "5"
        //     }

        //     setParticipants(participants)
        //     setSummary(summary)
        // }, 1000)
    }

    const selectAttendee = (id) => {
        selectedAttendee.current = id;
        fetchDetailAttendee();
    }


    const getInitialName = (name) => {
        let initial = '';
        if (name) {
            const initialName = name.split(' ');
            initial = initialName[0][0] + (initialName.length > 1 ? initialName[1][0] : '');
        }

        return initial;
    }

    const getResourceImage = (product) => {
        return product.reduce((temp, item) => { return [...temp, item.path_file] }, []);
    }

    useEffect(() => {
        async function fetchAttendees() {
            setLoading(true)
            setAttendees([])
            try {
                const attendees = await getAttendes(keywordSearch);
                const excludeUser = attendees.data.participants.filter(item => item.id !== auth?.user.participant.id)
                setAttendees(excludeUser);
            } catch (error) {
                MySwal.fire({
                    title: 'Error',
                    html: "Can't fetch data",
                    icon: 'error'
                })
            }



            // await setTimeout(() => {
            //     setAttendees([{
            //         id: 1,
            //         company_name: 'PT A',
            //         company_type: 'IT',
            //     }, {
            //         id: 2,
            //         company_name: 'PT B',
            //         company_type: 'Food',
            //     }]);
            // }, 2000)
            setLoading(false);
        }

        const delayDebounceFn = setTimeout(() => {
            fetchAttendees()
        }, keywordSearch ? 2000 : 0)

        return () => clearTimeout(delayDebounceFn)
    }, [keywordSearch])

    const [showModal, setShowModal] = useState(false);
    const [requestType, setRequstType] = useState('send_request');
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = (type) => {
        setShowModal(true);
        setRequstType(type);
    }

    const [selectLoading, setSelectLoading] = useState(false);
    const [showModalGet, setShowModalGet] = useState(false);
    const handleCloseModalGet = () => setShowModalGet(false);
    const handleShowModalGet = () => setShowModalGet(true);

    function updateRequest() { }
    return (
        <>
            {/* begin: Modal */}
            <SendingRequestDsb
                participants={participants}
                requestType={requestType}
                showModal={showModal}
                handleCloseModal={handleCloseModal} />

            <GettingRequestDsb
                participants={participants['getting_request']}
                showModalGet={showModalGet}
                isAdmin={true}
                handleUpdateRequest={updateRequest}
                handleCloseModalGet={handleCloseModalGet} />
            {/* begin: Modal */}

            {/* begin::Attendees List */}
            <div className='row'>
                <div className='col-xxl-6'>
                    <div className='card card-xl-stretch mb-xl-8'>
                        {/* begin::Header */}
                        <div className='card-header border-0 pt-5'>
                            <div className='card-title text-center d-block w-100'>
                                <div className='d-flex align-items-center position-relative my-1'>
                                    <div className='fs-3 position-absolute ms-4 text-gray-600'>
                                        <RiSearchLine />
                                    </div>
                                    <input
                                        type='text'
                                        data-kt-user-table-filter='search'
                                        className='form-control form-control-solid ps-14'
                                        placeholder='Search attendees'
                                        value={keywordSearch}
                                        onChange={(e) => setKeywordSearch(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* end::Header */}
                        {/* begin::Body */}
                        <div className='card-body pt-5'>
                            {loading && (
                                <div className='d-flex justify-content-center'>
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                </div>
                            )}
                            {/* begin::Item */}
                            <div className="scroll scroll-pull" data-scroll="true" data-wheel-propagation="true" style={{ height: '430px' }}>
                                {attendees.length > 0 ? attendees.map((item, index) => {
                                    return (
                                        <div key={index} className='d-flex attendee-item align-items-sm-center p-3 rounded' onClick={() => selectAttendee(item.id)} style={{ backgroundColor: item.id === selectedAttendee.current ? '#e7f4ff' : '' }}>
                                            { createProfileImage('40px', item) }
                                            <div className='d-flex align-items-center flex-row-fluid flex-wrap ps-4'>
                                                <div className='flex-grow-1 me-2'>
                                                    <span className='text-gray-800 text-hover-primary fs-5 fw-bold'>
                                                        {item.company_name}
                                                    </span>
                                                    {/* {
                                                        item.company_type_name && (!item.province_name || item.province_name === '-') && (
                                                            <span className='text-muted fw-bold d-block fs-7'>{item.company_type_name}</span>
                                                        )
                                                    }
                                                    
                                                    {
                                                        (item.company_type_name && item.company_type_name !== '-') && (item.province_name && item.province_name !== '-') && (
                                                            <span className='text-muted fw-bold d-block fs-7'>{item.company_type_name} | {item.province_name}, {item.country}</span>
                                                        )
                                                    } */}
                                                </div>
                                                {selectLoading && item.id === selectedAttendee.current &&
                                                    <div className='d-flex justify-content-center'>
                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                                            Please wait...
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                }) : <div className={`${loading ? 'd-none' : ''} w-100 text-center`}>Data not found</div>}
                            </div>
                            {/* end::Item */}
                        </div>
                    </div>
                </div>

                <div className='col-xxl-6 mt-8 mt-xl-0'>
                    <div className='card card-xl-stretch mb-xl-8'>
                        <div className='card-header justify-content-start align-items-center border-0 pt-5'>
                            <div className='card-title fs-2'>
                                <RiCalendarLine />
                            </div>
                            <h3 className='card-label fw-bold text-dark mt-3'>Company Details</h3>
                        </div>

                        {/* begin::Body */}
                        <div className={`card-body pt-5 ${selectedAttendee.current == null ? 'd-none' : 'd-block'}`}>
                            <div className='d-flex align-items-sm-center p-3 rounded'>
                            { createProfileImage('70px', detailAttendee) }
                                <div className='d-flex align-items-center flex-row-fluid flex-wrap ps-4'>
                                    <div className='flex-grow-1 me-2'>
                                        <span className='text-gray-800 text-hover-primary fs-4 fw-bold'>
                                            {detailAttendee.company_name}
                                        </span>
                                        <span className='text-muted fw-bold d-block fs-7'>{detailAttendee.contact_person}</span>
                                        {/* <span className='text-muted fw-bold d-block fs-7'>{detailAttendee.company_type_name}</span> */}
                                    </div>
                                </div>
                            </div>
                            <ul className="nav nav-line-tabs mb-10" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation" onClick={() => setActiveTab('about')}>
                                    <span className="nav-link text-gray-400 fw-bold cursor-pointer" style={activeTab === 'about' ? active : {}} id="pills-home-tab" data-bs-toggle="tab" data-bs-target="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">About</span>
                                </li>
                                <li className="nav-item" role="presentation" onClick={() => setActiveTab('appointment')}>
                                    <span className="nav-link text-gray-400 fw-bold cursor-pointer" style={activeTab === 'appointment' ? active : {}} id="pills-appointment-tab" data-bs-toggle="tab" data-bs-target="#pills-appoinment" role="tab" aria-controls="pills-appoinment" aria-selected="true">Appoinment</span>
                                </li>
                                <li className="nav-item" role="presentation" onClick={() => setActiveTab('product')}>
                                    <span className="nav-link text-gray-400 fw-bold cursor-pointer" style={activeTab === 'product' ? active : {}} id="pills-profile-tab" data-bs-toggle="tab" data-bs-target="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Product</span>
                                </li>
                            </ul>
                            <div className="tab-content mt-3" id="pills-tabContent">
                                <div className={`tab-pane fade ${activeTab === 'about' ? 'show active' : ''}`} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    {/* <div className='section-about d-flex flex-column'>
                                        <span className='fw-bold text-gray-400 fs-7'>Location</span>
                                        <span className='fw-bold fs-5'>{detailAttendee.country} - {detailAttendee.district}</span>
                                    </div> */}
                                    <div className='section-about d-flex flex-column mt-2'>
                                        <span className='fw-bold text-gray-400 fs-7'>Company Website</span>
                                        <span className='fw-bold fs-5'>{detailAttendee.company_website}</span>
                                    </div>
                                    <div className='section-about d-flex flex-column mt-2'>
                                        <span className='fw-bold text-gray-400 fs-7'>Description</span>
                                        <p className='fs-7 fw-semibold'>
                                            {detailAttendee.description}
                                        </p>
                                    </div>
                                </div>
                                <div className={`tab-pane fade ${activeTab === 'appointment' ? 'show active' : ''}`} id="pills-appoinment" role="tabpanel" aria-labelledby="pills-appoinment-tab">
                                    {/* begin::Row */}
                                    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                                        {/* begin::Col */}
                                        <div className='col-md-6 mb-md-5 mb-xl-10'>

                                            <CardsWidget20
                                                className='h-md-50 mb-5 mb-xl-10'
                                                description='Received Request'
                                                color='#090655'
                                                labelColor='dark'
                                                img={''}
                                                isLoading={loadingSummary}
                                                summaryData={summarry}
                                                participantData={participants}
                                                onClickModal={handleShowModalGet}
                                                onClickRefresh={fetchSummary}
                                            />

                                            <CardsWidget7
                                                className='h-md-50 mb-5 mb-xl-10'
                                                description='Confirmed Request'
                                                icon={false}
                                                isLoading={loadingSummary}
                                                participants={participants.confirmed_meetings}
                                                stats={parseInt(summarry.confirmed_meetings || '0')}
                                                labelColor='light'
                                                textColor='gray-300'
                                                onClickModal={() => handleShowModal('confirmed_meetings')}
                                                onClickRefresh={fetchSummary}
                                            />
                                        </div>
                                        {/* end::Col */}

                                        {/* begin::Col */}
                                        <div className='col-md-6 mb-md-5 mb-xl-10'>
                                            <CardsWidget7
                                                className='h-md-50 mb-5 mb-xl-10'
                                                description='Send Request'
                                                icon={false}
                                                isLoading={loadingSummary}
                                                participants={participants.send_request}
                                                stats={parseInt(summarry.send_request || '0')}
                                                labelColor='light'
                                                textColor='gray-300'
                                                onClickModal={() => handleShowModal('send_request')}
                                                onClickRefresh={fetchSummary}
                                            />
                                            <CardsWidget7
                                                className='h-md-50 mb-5 mb-xl-10'
                                                description='Declined Request'
                                                icon={false}
                                                isLoading={loadingSummary}
                                                participants={participants.declined_request}
                                                stats={parseInt(summarry.declined_request || '0')}
                                                color='#F1416C'
                                                labelColor='dark'
                                                textColor='gray-300'
                                                onClickModal={() => handleShowModal('declined_request')}
                                                onClickRefresh={fetchSummary}
                                            />
                                        </div>
                                        {/* end::Col */}
                                    </div>
                                    {/* end::Row */}
                                </div>
                                <div className={`tab-pane fade ${activeTab === 'product' ? 'show active' : ''}`} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                    <div className="row g-10 row-cols-2 row-cols-lg-3">
                                        {Object(detailAttendee).hasOwnProperty('product') ? (detailAttendee.product.length > 0 ? detailAttendee.product.map((item, index) => {
                                            return (
                                                <div key={index} className="col rounded" onClick={() => { setToggler(!toggler); setTogglerIndex(index) }}>
                                                    <div className="d-block overlay" data-fslightbox="lightbox-hot-sales">
                                                        <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px" style={{ backgroundImage: `url('${item.path_file}')` }}>
                                                        </div>
                                                        <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                            <i className="ki-outline ki-eye fs-3x text-white"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : '') : ''}
                                    </div>
                                    {/* <FsLightbox
                                        toggler={toggler}
                                        sources={detailAttendee.product_view || []}
                                        sourceIndex={togglerIndex}
                                        onClose={() => setToggler(!toggler)}
                                    /> */}
                                </div>
                            </div>
                        </div>
                        <div id="emptyAttendee" className={`card-body pt-5 ${selectedAttendee.current == null ? 'd-block' : 'd-none'}`}>
                            <div className='text-center'>
                                <h3 className="fs-1 fw-bold">
                                    Welcome to the Atendees List
                                    <br />of Business Matching App
                                </h3>
                                <p className="text-gray-600 mt-3">
                                    It's time to expand your business.<br />
                                    Set your appointment meeting and experience the<br />
                                    benefits today!
                                </p>
                                <img src="/ttc/illustration.png" className='img-fluid w-50' alt="attendees list illustrator" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* end::Attendees List */}

            {/* begin:Preview Image */}
            <Modal show={toggler} onHide={() => setToggler(!toggler)}>
                <Modal.Body>
                    <div className="position-relative">
                        <div className="d-flex w-100 justify-content-end position-absolute">
                            <div className="symbol symbol-30px symbol-circle" data-bs-toggle="tooltip">
                                <span className="symbol-label fw-bold bg-secondary text-inverse-warning">
                                    <span className="fs-1" onClick={() => setToggler(!toggler)}><RiCloseLine /></span>
                                </span>
                            </div>
                        </div>
                        {/* begin::Item */}
                        <div className="d-flex w-100 justify-content-center align-items-center">
                            <img className="img-fluid" alt="preview" src={detailAttendee.product ? detailAttendee.product_view[togglerIndex] : ''} />
                        </div>
                    </div>
                    {/* end::Item */}
                </Modal.Body>
            </Modal>
            {/* end:Preview Image */}
        </>
    )
}

const DashboardAdminWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
            <AttendeesPage />
        </>
    )
}

export { DashboardAdminWrapper };
