import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { postFcmToken } from '../auth/core/_requests';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
};


const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const MySwal = withReactContent(Swal)

export const getTokenFirebase = (setTokenFound) => {
    return getToken(messaging, { vapidKey: process.env.REACT_APP_FB_VAPID_KEY }).then((currentToken) => {
        if (currentToken) {
            // console.log('current token for client: ', currentToken);
            setTokenFound(true);
            postFcmToken(currentToken)
                .then(() => {})
                .catch(() => {
                    MySwal.fire({
                        title: 'Error',
                        html: "Can't post fcm token",
                        icon: 'error'
                      })
                })
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});