/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { defaultAlerts, defaultLogs, extractTime, KTIcon, toAbsoluteUrl } from '../../../helpers'
import NotificationRequest from '../../../../app/modules/auth/components/NotificationRequest'
import { getNotificationFromDb, postStatusMeeting } from '../../../../app/modules/auth/core/_requests'
import { getTokenFirebase, onMessageListener } from '../../../../app/modules/firebase/config'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Moment from 'react-moment';
import 'moment-timezone';
import { usePageData } from '../../../layout/core'

type ItemNotif = {
  id: number | null,
  detail_schedule_id: number | null,
  origin_company: string,
  company_type: string,
  contact_person: string,
  status_id: number | null,
  status_label: string,
  date: string,
  start_time: string,
  end_time: string,
  created_at: string,
  message: string,
  action_by: number
}

const HeaderNotificationsMenu: FC = () => {
  const MySwal = withReactContent(Swal)
  const [requestType, setRequestType] = useState('get_request')
  const [showModalNotif, setShowModalNotif] = useState(false)
  const [isTokenFound, setTokenFound] = useState(false);

  getTokenFirebase(setTokenFound);
  onMessageListener().then((payload: any) => {
    const item = JSON.parse(payload.data.additional)

    const mapStatusLabel = {
      1: 'get_request',
      2: 'confirmed_meetings',
      3: 'decline_request',
      4: 'cancelled_request'
    }

    item.status_label = mapStatusLabel[item.status_id];
    
    setNotifications([item, ...notifications].slice(0, 10))
    setIsNewNotif(true)
    // MySwal.fire({
    //   title: 'Success',
    //   html: JSON.stringify(item),
    //   icon: 'success'
    // })
  }).catch(err => console.log('failed: ', err));

  useEffect(() => {
    if (isTokenFound) {
      // MySwal.fire({
      //   title: 'Success',
      //   html: 'Notification permission enabled',
      //   icon: 'success'
      // })
    }
  }, [isTokenFound])

  const notifType = {
    get_request: {
      bgColor: '#3F98FF',
      title: 'Request Appointment meeting from',
    },
    decline_request: {
      bgColor: '#F23F6C',
      title: 'Meeting request rejected by',
    },
    confirmed_meetings: {
      bgColor: '#F97B22',
      title: 'Schedule Meeting with PT',
    },
    cancelled_request: {
      bgColor: '#F23F6C',
      title: 'Meeting request rejected by',
    },
  }

  const [notifications, setNotifications] = useState<Array<ItemNotif>>([])

  const [participants, setParticipants] = useState<Array<ItemNotif>>([])

  const handleOpenModal = (alert) => {
    console.log('select', alert)
    setShowModalNotif(true);
    setRequestType(alert.status_label);
    setParticipants(notifications.filter((item: ItemNotif) => item.id === alert.id && item.status_id === alert.status_id))
  }

  const handleCloseModal = () => {
    setShowModalNotif(false);
  }


  function fetchNotif() {
    getNotificationFromDb()
      .then((response) => {
        const data = response.data.data.map((item) => {
          return {
            ...item,
            // status_label: item.status_id === 1 ? 'get_request' : (item.status_id === 2 ? 'confirmed_meetings' : 'declined_request')
          }
        })
        setNotifications(data)
      })
      .catch((errors) => {
        MySwal.fire({
          title: 'Error',
          html: "Can't get notification data",
          icon: 'error'
        })
      })
    // await setTimeout(() => {
    //   const response = [
    //     {
    //       id: 1,
    //       detail_schedule_id: 1,
    //       origin_company: 'PT Confirmed 1',
    //       company_type: 'IT',
    //       contact_person: 'John Doe',
    //       status_id: 2,
    //       status_label: 'get_request',
    //       date: '2023-06-06 13:01:06',
    //       start_time: '10:30',
    //       end_time: '11:00',
    //       created_at: '2023-06-06 13:01:06'
    //     },
    //     {
    //       id: 2,
    //       detail_schedule_id: 2,
    //       origin_company: 'PT Reject 1',
    //       company_type: 'IT',
    //       contact_person: 'John Doe',
    //       status_id: 3,
    //       status_label: 'declined_request',
    //       date: '2023-06-06 13:01:06',
    //       start_time: '10:30',
    //       end_time: '11:00',
    //       created_at: '2023-06-06 13:01:06'
    //     },
    //     {
    //       id: 3,
    //       detail_schedule_id: 3,
    //       origin_company: 'PT Confirmed 1',
    //       company_type: 'IT',
    //       contact_person: 'John Doe',
    //       status_id: 2,
    //       status_label: 'confirmed_meetings',
    //       date: '2023-06-06 13:01:06',
    //       start_time: '10:30',
    //       end_time: '11:00',
    //       created_at: '2023-06-06 13:01:06'
    //     },
    //     {
    //       id: 4,
    //       detail_schedule_id: 3,
    //       origin_company: 'PT Confirmed 1',
    //       company_type: 'IT',
    //       contact_person: 'John Doe',
    //       status_id: 2,
    //       status_label: 'confirmed_meetings',
    //       date: '2023-06-06 13:01:06',
    //       start_time: '10:30',
    //       end_time: '11:00',
    //       created_at: '2023-06-06 13:01:06'
    //     },
    //     {
    //       id: 5,
    //       detail_schedule_id: 3,
    //       origin_company: 'PT Confirmed 1',
    //       company_type: 'IT',
    //       contact_person: 'John Doe',
    //       status_id: 2,
    //       status_label: 'confirmed_meetings',
    //       date: '2023-06-06 13:01:06',
    //       start_time: '10:30',
    //       end_time: '11:00',
    //       created_at: '2023-06-06 13:01:06'
    //     },
    //     {
    //       id: 6,
    //       detail_schedule_id: 3,
    //       origin_company: 'PT Confirmed 1',
    //       company_type: 'IT',
    //       contact_person: 'John Doe',
    //       status_id: 2,
    //       status_label: 'confirmed_meetings',
    //       date: '2023-06-06 13:01:06',
    //       start_time: '10:30',
    //       end_time: '11:00',
    //       created_at: '2023-06-06 13:01:06'
    //     },
    //     {
    //       id: 7,
    //       detail_schedule_id: 3,
    //       origin_company: 'PT Confirmed 1',
    //       company_type: 'IT',
    //       contact_person: 'John Doe',
    //       status_id: 2,
    //       status_label: 'confirmed_meetings',
    //       date: '2023-06-06 13:01:06',
    //       start_time: '10:30',
    //       end_time: '11:00',
    //       created_at: '2023-06-06 13:01:06'
    //     },
    //     {
    //       id: 8,
    //       detail_schedule_id: 3,
    //       origin_company: 'PT Confirmed 1',
    //       company_type: 'IT',
    //       contact_person: 'John Doe',
    //       status_id: 2,
    //       status_label: 'confirmed_meetings',
    //       date: '2023-06-06 13:01:06',
    //       start_time: '10:30',
    //       end_time: '11:00',
    //       created_at: '2023-06-06 13:01:06'
    //     },
    //     {
    //       id: 9,
    //       detail_schedule_id: 3,
    //       origin_company: 'PT Confirmed 1',
    //       company_type: 'IT',
    //       contact_person: 'John Doe',
    //       status_id: 2,
    //       status_label: 'confirmed_meetings',
    //       date: '2023-06-06 13:01:06',
    //       start_time: '10:30',
    //       end_time: '11:00',
    //       created_at: '2023-06-06 13:01:06'
    //     },
    //     {
    //       id: 10,
    //       detail_schedule_id: 3,
    //       origin_company: 'PT Limit 10',
    //       company_type: 'IT',
    //       contact_person: 'John Doe',
    //       status_id: 2,
    //       status_label: 'confirmed_meetings',
    //       date: '2023-06-06 13:01:06',
    //       start_time: '10:30',
    //       end_time: '11:00',
    //       created_at: '2023-06-06 13:01:06'
    //     },
    //     {
    //       id: 11,
    //       detail_schedule_id: 3,
    //       origin_company: 'PT Limit 10',
    //       company_type: 'IT',
    //       contact_person: 'John Doe',
    //       status_id: 2,
    //       status_label: 'confirmed_meetings',
    //       date: '2023-06-06 13:01:06',
    //       start_time: '10:30',
    //       end_time: '11:00',
    //       created_at: '2023-06-06 13:01:06'
    //     }
    //   ]

    //   setNotifications(response.slice(0, 10))
    // }, 100);
  }

  const [loadingUpdate, setLoadingUpdate] = useState(false);

  async function updateRequest(detail_schedule_id, status_id, notif_id = undefined) {
    let payload = {
      detail_schedule_id: detail_schedule_id,
      status_id: status_id,
      notif_id: notif_id
    }
    // return
    try {
      setLoadingUpdate(true)
      const response = await postStatusMeeting(payload)
      fetchNotif()

      if (response.data.status) {
        MySwal.fire({
          title: 'Success',
          html: response.data.message,
          icon: 'success'
        })
      } else {
        throw new Error("Error");
      }
    } catch (error) {
      MySwal.fire({
        title: 'Error',
        html: "error",
        icon: 'error'
      })
    } finally {
      setLoadingUpdate(false)
      setShowModalNotif(false)
    }
  }

  const { setIsNewNotif, isNewNotif } = usePageData()
  function addNotif() {
    setIsNewNotif(true)
    // const item = {
    //   id: 11,
    //   detail_schedule_id: 3,
    //   origin_company: 'PT Push 1',
    //   company_type: 'IT',
    //   contact_person: 'John Doe',
    //   status_id: 2,
    //   status_label: 'confirmed_meetings',
    //   date: '2023-06-06 13:01:06',
    //   start_time: '10:30',
    //   end_time: '11:00',
    //   created_at: '2023-06-06 13:01:06'
    // }

    // setNotifications([item, ...notifications].slice(0, 10))
  }

  useEffect(() => {
    fetchNotif()
  }, [isNewNotif])

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <NotificationRequest
        participants={participants}
        requestType={requestType}
        showModalNotif={showModalNotif}
        isLoadingUpdate={loadingUpdate}
        handleUpdateNotif={updateRequest}
        handleCloseModalNotif={handleCloseModal}
      />
      <h3 className='fw-bold px-9 mt-6'>
        Notifications
      </h3>
      <div className='tab-content'>
        <div className='tab-pane fade active show' id='kt_topbar_notifications_1' role='tabpanel'>
          <div className='scroll-y mh-325px mb-5 px-8'>
            {
              notifications.length ? notifications.map((alert, index) => {
                const extract = extractTime(alert.start_time, alert.end_time, alert.date)
                return (
                  <div key={`alert${index}`} className='d-flex flex-stack py-4' onClick={() => handleOpenModal(alert)}>
                    <div className='d-flex align-items-start'>
                      <div className='symbol symbol-35px me-4'>
                        <div className='symbol symbol-circle me-2' style={{ height: '10px', width: '10px', backgroundColor: notifType[alert.status_label].bgColor }}></div>
                      </div>

                      <div className='mb-0 me-2'>
                        <a href='#' className='fs-6 text-gray-800 text-hover-primary'>
                          {alert.message} <span className='fw-bolder'>{alert.origin_company}</span>
                        </a>
                        <div className='text-gray-400 fs-7'>
                          <Moment date={extract.date} format="DD MMM YYYY"></Moment>
                          &nbsp; &nbsp; &nbsp;
                          {extract.timeStart} &nbsp; - &nbsp; {extract.timeEnd}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }) : 'Empty Notifications'
            }
          </div>

          <div className='py-3 text-center border-top'>
            {/* <button onClick={() => addNotif()}>add notif</button> */}
            {/* <Link
              to='/crafted/pages/profile'
              className='btn btn-color-gray-600 btn-active-color-primary'
            >
              View All <KTIcon iconName='arrow-right' className='fs-5' />
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export { HeaderNotificationsMenu }
