import {FC, useState, createContext, useContext} from 'react'
import {
  QueryState,
  QueryRequestContextProps,
  initialQueryRequest,
  WithChildren,
} from '../../../../_metronic/helpers'

const QueryRequestContext = createContext<{
  state: {
    customer_type?: string | ''
    district_id?: string | number | null,
    sort?: string
    order?: 'asc' | 'desc',
    date?: string,
    current_page: number | string,
    per_page: number | 10 | 30 | 50 | 100
  },
  updateState: (updates: {
    customer_type?: string | ''
    district_id?: string | number | null,
    date?: string,
    sort?: string
    order?: 'asc' | 'desc'
    current_page: number | string,
    per_page: number | 10 | 30 | 50 | 100
  }) => void,
}>({
  state: {
    current_page: 1,
    per_page: 10
  },
  updateState: () => {}
})

const QueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<{
    sort?: string
    order?: 'asc' | 'desc'
    current_page: number | string
    per_page: number | 10 | 30 | 50 | 100
    links?: Array<{label: string; active: boolean; url: string | null; page?: number | null}>
  }>({
    current_page: 1,
    per_page: 10
  })

  const updateState = (updates: {
    date?: string
    sort?: string
    order?: 'asc' | 'desc'
    current_page: number | string
    per_page: number | 10 | 30 | 50 | 100
    links?: Array<{label: string; active: boolean; url: string | null; page?: number | null}>
  }) => {
    const updatedState = {...state, ...updates} 
    setState({ ...updatedState })
  }

  return (
    <QueryRequestContext.Provider value={{state, updateState}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export {QueryRequestProvider, useQueryRequest}
