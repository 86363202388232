/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useQueryResponseLoading, useQueryResponsePagination } from './QueryResponseProvider'
import { useQueryRequest } from './QueryRequestProvider'
const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }

  if (label === 'Next &raquo;') {
    return 'Next'
  }

  return label
}

const ReportPagination = () => {
  // const pagination = {
  //   "current_page": 1,
  //   "data": [
  //     {
  //       "company_name": "dummy5",
  //       "location": "5",
  //       "company_type": "1",
  //       "getting_request": 3,
  //       "send_request": 0,
  //       "confirmed": 0,
  //       "declined": 0
  //     },
  //     {
  //       "company_name": "dummy5",
  //       "location": "5",
  //       "company_type": "1",
  //       "getting_request": 3,
  //       "send_request": 0,
  //       "confirmed": 0,
  //       "declined": 0
  //     },
  //     {
  //       "company_name": "dummy5",
  //       "location": "5",
  //       "company_type": "1",
  //       "getting_request": 3,
  //       "send_request": 0,
  //       "confirmed": 0,
  //       "declined": 0
  //     },
  //     {
  //       "company_name": "PT. Intermilano",
  //       "location": "1",
  //       "company_type": "Importers",
  //       "getting_request": 0,
  //       "send_request": 0,
  //       "confirmed": 0,
  //       "declined": 0
  //     },
  //     {
  //       "company_name": "PT GK 10",
  //       "location": "1",
  //       "company_type": "1",
  //       "getting_request": 0,
  //       "send_request": 0,
  //       "confirmed": 0,
  //       "declined": 0
  //     },
  //     {
  //       "company_name": "PT Dummy",
  //       "location": "2",
  //       "company_type": "1",
  //       "getting_request": 0,
  //       "send_request": 0,
  //       "confirmed": 0,
  //       "declined": 0
  //     }
  //   ],
  //   "first_page_url": "http://172.16.1.42:8081/api/report-participant?page=1",
  //   "from": 1,
  //   "last_page": 1,
  //   "last_page_url": "http://172.16.1.42:8081/api/report-participant?page=1",
  //   "links": [
  //     {
  //       "url": null,
  //       "label": "&laquo; Previous",
  //       "active": false
  //     },
  //     {
  //       "url": "http://172.16.1.42:8081/api/report-participant?page=1",
  //       "label": "1",
  //       "active": true
  //     },
  //     {
  //       "url": "http://172.16.1.42:8081/api/report-participant?page=2",
  //       "label": "2",
  //       "active": false
  //     },
  //     {
  //       "url": null,
  //       "label": "Next &raquo;",
  //       "active": false
  //     }
  //   ],
  //   "next_page_url": null,
  //   "path": "http://172.16.1.42:8081/api/report-participant",
  //   "per_page": 10,
  //   "prev_page_url": null,
  //   "to": 6,
  //   "total": 6
  // }
  // type PaginationLink = {
  //   url: string | null,
  //   label: string | null,
  //   active: boolean
  // }

  // type PaginationState = {
  //   current_page: number | string,
  //   data?: Array<Report>
  //   first_page_url?: string | null,
  //   from?: string | number,
  //   last_page?: string | number,
  //   last_page_url?: string,
  //   links: Array<PaginationLink>
  //   next_page_url?: string | null,
  //   path?: string | null,
  //   per_page: number | string | null,
  //   prev_page_url?: string | null,
  //   to?: number | string,
  //   total?: number | string
  // }

  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const updatePage = (current_page: number | null | string) => {
    if (!current_page || isLoading || pagination.current_page === current_page) {
      return
    }

    updateState({current_page, per_page: pagination.per_page || 10})
  }

  return (
    <div className='row mt-10'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            {pagination.links
              ?.map((link) => {
                return {...link, label: mappedLabel(link.label)}
              })
              .map((link) => (
                <li
                  key={link.label}
                  className={clsx('page-item', {
                    active: link.active,
                    disabled: isLoading,
                    previous: link.label === 'Previous',
                    next: link.label === 'Next',
                  })}
                >
                  <a
                    className={clsx('page-link', {
                      'page-text': link.label === 'Previous' || link.label === 'Next',
                      'me-5': link.label === 'Previous',
                    })}
                    onClick={() => {
                      const isPrevious = link.label === 'Previous'
                      const isNext = link.label === 'Next'

                      if(isPrevious && pagination.prev_page_url === null) return
                      if(isNext && pagination.next_page_url === null) return
                      
                      let page = parseInt(link.label)
                      if(isPrevious) page = parseInt(pagination.current_page + '') - 1
                      else if(isNext) page = parseInt(pagination.current_page + '') + 1
                      
                      updatePage(page)
                    }}
                    style={{cursor: 'pointer'}}
                  >
                    {mappedLabel(link.label)}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export {ReportPagination}
