/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Moment from 'react-moment';
import 'moment-timezone';
import { Link } from 'react-router-dom';
import { extractTime } from '../../../helpers';

type Props = {
  className: string,
  schedules: Array<{
    id: string,
    date: string,
    total: number,
    details: Array<{
      id: string,
      time_start: string,
      time_end: string,
      request_type: string,
      by: string,
      created_at?: string
    }>
  }>
}

const ScheduleWidgetDsb: React.FC<Props> = ({ className, schedules }) => {
  const activeDate = {
    backgroundColor: '#F1416C',
    color: '#ffffff'
  }

  const grayDate = {
    backgroundColor: '#ffffff',
    color: '#000000'
  }

  const [selectDate, setSelectDate] = useState(schedules[0]);
  const [selectDateIndex, setSelectDateIndex] = useState(0);
  const [selectDateBg, setSelectDateBg] = useState('');

  useEffect(() => {
    if (schedules.length > 0) {
      const index = schedules.findIndex(item => item.id === selectDateBg)
      const defaultIndex = (index < 0 ? 0 : index)
      setSelectDate(schedules[defaultIndex])
      setSelectDateIndex(defaultIndex)
      setSelectDateBg(schedules[defaultIndex].id)
    }
  }, [schedules, selectDateBg])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <div className='d-flex flex-column justify-content-center'>
          <h3 className='card-title fw-bold text-dark'>Schedule</h3>
          <span>Total {selectDate.total} meetings</span>
        </div>
        <div className='card-toolbar'>
          <h2 className='fw-bold text-dark'>
           	May 2024
          </h2>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        <div className="row align-items-start mt-2 mb-4">
          {schedules && schedules.map(schedule => {
            return (
              <div key={schedule.id} onClick={() => setSelectDateBg(schedule.id)} className="col text-center">
                <span className="btn btn-sm fw-bold ps-10 pe-10 rounded-pill" style={selectDateBg === schedule.id ? activeDate : grayDate}>
                  <Moment date={schedule.date} format='dddd'></Moment> <br />
                  <Moment date={schedule.date} format='DD'></Moment>
                </span>
              </div>
            )
          })}
        </div>
        {/* begin::Item */}
        {schedules.length > 0 ? selectDate.details.map((schedule, index) => {
          const getAmPm = schedules[selectDateIndex].date.substr(0, 10) + ' ' + schedule.time_end
          const extract = extractTime(schedule.time_start, schedule.time_end, schedules[selectDateIndex].date)
          return (
            <div key={schedule.id} className='d-flex align-items-center mb-8'>
              <span className='bullet bullet-vertical h-60px bg-success'></span>
              <div className='flex-grow-1 ps-3'>
                <span className='fw-bold fs-4'>
                  {extract.timeStart} - {extract.timeEnd}
                  <span className='text-muted fw-bold fs-8'>&nbsp;</span>
                </span>
                <span className='text-gray-700 text-hover-primary fw-bold fs-6 d-block'>
                  Appoinment Meeting
                </span>
                <span className='text-muted fw-semibold d-block fs-8'>{schedule.request_type === 'getting' ? 'Invited By' : 'Inviting'} <span className='text-primary'>{schedule.by}</span></span>
              </div>
              <span className='badge badge-light-success fs-8 fw-bold'>
                <Moment fromNow>{extract.date + ' ' + extract.timeStart}</Moment>
              </span>
            </div>
          )
        }) : ''}
      </div>
      {/* end::Body */}
      {schedules.length > 0 && (
        <div className="card-footer">
          <div className='col-12'>
            <Link to="/schedules" className='btn btn-color-gray-600 btn-active-color-primary w-100'>View All</Link>
          </div>
        </div>
      )}
    </div>
  )
}

export { ScheduleWidgetDsb }
