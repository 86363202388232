import { ColumnInstance, Row, useTable } from "react-table"
import { KTCardBody } from "../../../../_metronic/helpers"
import { useMemo } from "react"
import { reportColumns } from "./columns/_columns"
import { Report } from "./_models"
import { CustomHeaderColumn } from "./columns/CustomHeaderColumn"
import { CustomRow } from "./columns/CustomRow"
import { ReportPagination } from "./ReportPagination"
import { useQueryResponseData, useQueryResponseLoading } from "./QueryResponseProvider"


const ReportTable = () => {
    const report = useQueryResponseData()
    const isLoading = useQueryResponseLoading()
    const data = useMemo(() => report, [report])
    const columns = useMemo(() => reportColumns, [])
    const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
        columns,
        data,
    })
    return (
        <KTCardBody className='py-4'>
            <div className='table-responsive'>
                <table
                    id='kt_table_users'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    {...getTableProps()}
                >
                    <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            {headers.map((column: ColumnInstance<Report>) => (
                                <CustomHeaderColumn key={column.id} column={column} />
                            ))}
                        </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                        {rows.length > 0 ? (
                            rows.map((row: Row<Report>, i) => {
                                prepareRow(row)
                                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                            })
                        ) : (
                            <tr>
                                <td colSpan={7}>
                                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                        No matching records found
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <ReportPagination />
            {/* <UsersListPagination /> */}
            {isLoading && (
                <div className='d-flex justify-content-center'>
                    <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                </div>
            )}
        </KTCardBody>
    )
}

export { ReportTable }
