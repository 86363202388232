import { FC, useEffect, useState } from "react";
import { RiCalendarLine } from "react-icons/ri";
import { PageTitle } from "../../../_metronic/layout/core";
import Moment from 'react-moment';
import 'moment-timezone';
import { Modal } from "react-bootstrap";
import { extractTime } from "../../../_metronic/helpers";
import { getDetailScheduleByCompany } from "../../modules/auth/core/_requests";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { getInitialName } from "../../../_metronic/helpers";

const SchedulePage: FC = () => {
    const MySwal = withReactContent(Swal)

    const [schedules, setSchedules] = useState<Array<{
        date: string,
        participant_id: number | null,
        schedule_id: number | null,
        detail: Array<{
            time: string,
            end_time: string,
            type: string,
            is_available: number,
            slot_id: number | null,
            company_name: string
        }>
    }>>([]);

    const [detailMeeting, setDetailMeeting] = useState<{
        timeStart: string,
        timeEnd: string,
        company_name: string
    }>({
        timeStart: '',
        timeEnd: '',
        company_name: ''
    })

    const [loading, setLoading] = useState(false)
    const [showDetailMeeting, setShowDetailMeeting] = useState(false)
    const [totalMeeting, setTotalMeeting] = useState(0);

    useEffect(() => {
        async function fetchDetailSchedule() {
            setLoading(true)
            try {
                const response = await getDetailScheduleByCompany()
                setSchedules(response.data)
                setTotalMeeting(
                    response.data.length ? response.data[0].detail.filter(item => item.is_available === 0).length : 0)
                setLoading(false)
                
            } catch (error) {
                MySwal.fire({
                    title: 'Error',
                    html: "Can't fetch data",
                    icon: 'error'
                })
                setLoading(false)
            }
        }

        fetchDetailSchedule()
        // setTimeout(() => {
        //     let dt = [
        //         {
        //             "participant_id": 33,
        //             "schedule_id": 1,
        //             "date": "2023-06-23 07:16:36",
        //             "detail": [
        //                 {
        //                     "slot_id": 1,
        //                     "time": "07:00:00",
        //                     "type": "AM",
        //                     "is_available": 0,
        //                     "company_name": 'PT ABC'
        //                 },
        //                 {
        //                     "slot_id": 2,
        //                     "time": "07:30:00",
        //                     "type": "AM",
        //                     "is_available": 0
        //                 },
        //                 {
        //                     "slot_id": 3,
        //                     "time": "08:00:00",
        //                     "type": "AM",
        //                     "is_available": 0
        //                 },
        //                 {
        //                     "slot_id": 4,
        //                     "time": "08:30:00",
        //                     "type": "AM",
        //                     "is_available": 0
        //                 },
        //                 {
        //                     "slot_id": 5,
        //                     "time": "09:00:00",
        //                     "type": "AM",
        //                     "is_available": 0
        //                 },
        //                 {
        //                     "slot_id": 6,
        //                     "time": "09:30:00",
        //                     "type": "AM",
        //                     "is_available": 0
        //                 }
        //             ]
        //         },
        //         {
        //             "participant_id": 33,
        //             "schedule_id": 2,
        //             "date": "2023-06-24 07:16:36",
        //             "detail": [
        //                 {
        //                     "slot_id": 7,
        //                     "time": "07:00:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 },
        //                 {
        //                     "slot_id": 8,
        //                     "time": "07:30:00",
        //                     "type": "AM",
        //                     "is_available": 0
        //                 },
        //                 {
        //                     "slot_id": 9,
        //                     "time": "08:00:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 },
        //                 {
        //                     "slot_id": 10,
        //                     "time": "08:30:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 },
        //                 {
        //                     "slot_id": 11,
        //                     "time": "09:00:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 },
        //                 {
        //                     "slot_id": 12,
        //                     "time": "09:00:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 }
        //             ]
        //         },
        //         {
        //             "participant_id": 33,
        //             "schedule_id": 2,
        //             "date": "2023-06-24 07:16:36",
        //             "detail": [
        //                 {
        //                     "slot_id": 7,
        //                     "time": "07:00:00",
        //                     "type": "AM",
        //                     "is_available": 0
        //                 },
        //                 {
        //                     "slot_id": 8,
        //                     "time": "07:30:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 },
        //                 {
        //                     "slot_id": 9,
        //                     "time": "08:00:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 },
        //                 {
        //                     "slot_id": 10,
        //                     "time": "08:30:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 },
        //                 {
        //                     "slot_id": 11,
        //                     "time": "09:00:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 },
        //                 {
        //                     "slot_id": 12,
        //                     "time": "09:00:00",
        //                     "type": "AM",
        //                     "is_available": 1
        //                 }
        //             ]
        //         },
        //     ]
        //     setSchedules(dt)
        //     setLoading(false)
        // }, 3000);

        // console.log(response.data)
    }, [])

    function openDetailMeeting(date, detail) {
        const extract = extractTime(detail.time, detail.end_time || '00:00', date.date)
        const selectedDate = {
            timeStart: extract.timeStartWith24,
            timeEnd: extract.timeEndWith24,
            company_name: detail.company_name
        }

        setDetailMeeting(selectedDate);
        setShowDetailMeeting(true)
    }

    return (
        <>
            {/* begin::Schedule Today */}
            <div className='row'>
                <div className='row'>
                    <div className='col-xxl-12'>
                        <div className='card card-xl-stretch mb-xl-8'>
                            {/* begin::Header */}
                            <div className='card-header border-0 pt-5 align-items-center'>
                                <div className='card-title flex-column'>
                                    <h3 className='card-label fw-bold text-dark'>Schedule</h3>
                                    <span className='text-muted mt-1 fw-semibold fs-7'>Total {totalMeeting} meetings.</span>
                                </div>
                                <div>
                                    <h3 className='card-label fw-bold text-dark'>
                                        <Moment date={schedules[0]?.date} format="DD MMM"></Moment>
                                        {/* &nbsp; - &nbsp;
                                        <Moment date={schedules[2]?.date} format="DD MMM YYYY"></Moment> */}
                                    </h3>
                                </div>
                            </div>
                            {/* end::Header */}
                            {/* begin::Body */}
                            <div className='card-body pt-5 text-center'>
                                {/* begin::Item */}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}

                                {!loading && schedules.length > 0 && (<table className="table table-rounded border border-gray-300 table-row-bordered table-row-gray-300 gy-7 gs-7">
                                    <thead>
                                        <tr className="fw-bold fs-4 text-gray-800">
                                            <th scope="col" className='col-1' style={{ width: '1%' }}>#</th>
                                            {schedules.length ? schedules.slice(0, 1).map((item, index) => {
                                                return (
                                                    <th key={index} scope="col" className='col' style={{ width: '30%' }}>
                                                        <Moment date={item.date} format="ddd DD/MM"></Moment>
                                                    </th>
                                                )
                                            }) : ''}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {schedules.length ? schedules[0].detail.map((item, indexTime) => {
                                            const firstColumn = schedules[0];
                                            const extract = extractTime(
                                                firstColumn.detail[indexTime].time,
                                                firstColumn.detail[indexTime].end_time || '00:00', 
                                                firstColumn.date)

                                            return (
                                                <tr key={indexTime}>
                                                    <td className='fw-bold'>
                                                        <span className={`${indexTime % 2 ? 'd-none' : 'd-flex'}`}>{extract.time + '.00'} &nbsp;
                                                            {/* <Moment date={extract.dateTime} format="A"></Moment> */}
                                                        </span>
                                                    </td>
                                                    {schedules.length ? schedules.slice(0, 1).map((item, indexDate) => {
                                                        const dt = schedules[indexDate]
                                                        const isAvailable = dt.detail[indexTime].is_available
                                                        return (
                                                            <>
                                                                <td
                                                                    key={`${indexTime}${indexDate}`}
                                                                    onClick={isAvailable ? undefined : () => openDetailMeeting(dt, dt.detail[indexTime])}
                                                                    className={`${isAvailable ? '' : 'schedule-selected'} text-center text-white`}>
                                                                    <div className={`${isAvailable ? 'd-none' : 'd-block'}`}>
                                                                        <span>{extract.timeStart} - {extract.timeEnd}</span>
                                                                        <p>{dt.detail[indexTime].company_name}</p>
                                                                    </div>
                                                                    <div className={`${isAvailable ? 'd-block' : 'd-none'}`}>
                                                                        <span>&nbsp;</span>
                                                                        <p>&nbsp;</p>
                                                                    </div>
                                                                </td>
                                                            </>
                                                        )
                                                    }) : ''}
                                                </tr>
                                            )
                                        }) : ''}
                                    </tbody>
                                </table>)}

                                {!loading && schedules.length === 0 && 'Empty Appoinment' }
                                {/* end::Item */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* end::Schedule Today */}

            {/* begin:Detail Schedule */}
            <Modal show={showDetailMeeting} onHide={() => setShowDetailMeeting(false)}>
                <Modal.Header className="justify-content-start align-items-baseline border-0" closeButton>
                    <div className='card-title fs-2'>
                        <RiCalendarLine />
                    </div>
                    <div className='card-title pt-2 ps-2'>
                        <h3 className='card-label fw-bold text-dark'>Appointment meeting</h3>
                        <span className='text-muted mt-1 fw-semibold fs-7'>With {detailMeeting.company_name}</span>
                    </div>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <div className='d-flex align-items-center'>
                        <div className="symbol bg-success symbol-circle me-2" style={{ height: '10px', width: '10px' }}></div>
                        <h4 className='mt-2 fw-bold fs-6'>
                            Starts&nbsp;
                            <span className='text-muted mt-1 fw-semibold fs-7'>{detailMeeting.timeStart}</span>
                        </h4>
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className="symbol bg-danger symbol-circle me-2" style={{ height: '10px', width: '10px' }}></div>
                        <h4 className='mt-2 fw-bold fs-6'>
                            Ends&nbsp;
                            <span className='text-muted mt-1 fw-semibold fs-7'>{detailMeeting.timeEnd}</span>
                        </h4>
                    </div>
                </Modal.Body>
            </Modal>
            {/* end:Detail Schedule */}
        </>
    )
}

const ScheduleWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Schedules</PageTitle>
            <SchedulePage />
        </>
    )
}

export { ScheduleWrapper };