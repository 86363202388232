import { Link, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { OverviewProfile, ProfilePage } from "./Overview";
import { ProductPage, ProductsWrapper } from "../Products/ProductWrapper";
import { useState } from "react";
import { useAuth } from "../../modules/auth";

const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Profile',
        path: '/profile/overview',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const ProfileWrapper = () => {
    const active = {
        borderBottom: '2px solid #3F98FF',
        fontWeight: 700,
        fontSize: '1.05rem',
    }

    const { auth } = useAuth()
    const isParticipant = auth?.user.role_id === 2

    const [activeTab, setActiveTab] = useState('overview')

    return (
        <>
            <PageTitle breadcrumbs={[]}>Profile</PageTitle>
            <div className='card mb-5 mb-xl-10'>
                <div className='card-body pt-9 pb-0'>
                    <ul className="nav nav-line-tabs mb-10" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation" onClick={() => setActiveTab('overview')}>
                            <span className={`nav-link fw-bold cursor-pointer ${activeTab === 'overview' ? 'text-black' : ''}`} style={activeTab === 'overview' ? active : {}} id="pills-home-tab" data-bs-toggle="tab" data-bs-target="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Profile Details</span>
                        </li>
                        { isParticipant && (
                        <li className="nav-item" role="presentation" onClick={() => setActiveTab('products')}>
                            <span className={`nav-link fw-bold cursor-pointer ${activeTab === 'products' ? 'text-black' : ''}`} style={activeTab === 'products' ? active : {}} id="pills-profile-tab" data-bs-toggle="tab" data-bs-target="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">My Products</span>
                        </li>)
                        }
                    </ul>
                    <div className="tab-content mt-3" id="pills-tabContent">
                        <div className={`tab-pane fade ${activeTab === 'overview' ? 'show active' : ''}`} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <ProfilePage/>
                        </div>
                        <div className={`tab-pane fade ${activeTab === 'products' ? 'show active' : ''}`} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <ProductPage/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileWrapper;