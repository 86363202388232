import { Modal } from "react-bootstrap";
import { createProfileImage, getInitialName } from "../../../../_metronic/helpers";

function SendingRequestDsb({ showModal, handleCloseModal, requestType, participants }) {
    const typeList = {
        send_request: {
            title: 'Send Request',
            desc: 'Your sent request to other participants.',
            buttonColor: '#F7D06033',
            textColor: '#7e8299',
            status: 'Pending'
        },
        getting_request: {
            title: 'Send Request',
            desc: 'You received request from other participants.',
            buttonColor: '#F7D06033',
            textColor: '#7e8299',
            status: 'Pending'
        },
        confirmed_meetings: {
            title: 'Confirmed Request',
            desc: 'Your confirmed request',
            buttonColor: '#198754',
            textColor: '#ffffff',
            status: 'Confirmed'
        },
        declined_request: {
            title: 'Declined Request',
            desc: 'Your declined request',
            buttonColor: '#dc3545',
            textColor: '#ffffff',
            status: 'Declined'
        }
    }

    return (
        <>
            {/* begin: Modal */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <div className='card-title text-center d-block w-100'>
                        <h3 className='card-label fw-bold text-dark'>{typeList[requestType].title}</h3>
                        <span className='text-muted mt-1 fw-semibold fs-7'>{typeList[requestType].desc}</span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="scroll scroll-pull" data-scroll="true" data-wheel-propagation="true" style={{ height: '400px' }}>
                        {/* begin::Item */}

                        {/* begin::Section */}
                        {
                            participants[requestType].length ? participants[requestType].map((item, index) => {
                                return (
                                    <div key={index} className='d-flex align-items-sm-center pb-3 pt-3' style={{ borderBottom: '2px solid #cdcdcd', borderBottomStyle: 'dashed' }}>
                                        { createProfileImage('40px', item) }
                                        <div className='d-flex align-items-center flex-row-fluid flex-wrap ps-4'>
                                            <div className='me-2' style={{ width: '80%' }}>
                                                <span className='text-gray-800 text-hover-primary fs-5 fw-bold'>
                                                    {item.company_name}&nbsp;<span className='text-muted fw-semibold fs-7'>{item.contact_person}</span>
                                                </span>
                                                {/* <span className='text-muted fw-bold d-block fs-7'>{item.company_type}</span> */}
                                            </div>
                                            <div style={{ width: '15%' }}>
                                                <span className='badge badge-light fw-bold my-2 p-2' style={{
                                                    backgroundColor: typeList[item.status_label || requestType ].buttonColor,
                                                    color: typeList[item.status_label || requestType ].textColor
                                                }}>{typeList[item.status_label || requestType].status}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : <div className="text-center w-100">Empty Data</div>
                        }
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default SendingRequestDsb;