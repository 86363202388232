import {FC, createElement} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import { 
    RiLayoutMasonryLine,
    RiCalendarLine,
    RiArchiveLine,
    RiContactsLine,
    RiBarChartBoxLine
 } from "react-icons/ri";

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean,
  isShow:boolean
}

const iconMap = ['RiCalendarLine', 'RiArchiveLine', 'RiContactsLine', 'RiLayoutMasonryLine', 'RiBarChartBoxLine']
const iconElemnet = [<RiCalendarLine/>, <RiArchiveLine/>, <RiContactsLine/>, <RiLayoutMasonryLine/>, <RiBarChartBoxLine/>]

const getIcon = (name: String) => {
  return iconElemnet[iconMap.findIndex(icon => icon === name)];
};

const SidebarMenuItemTtc: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon = '',
  isShow = false
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  console.log('cek menu', isShow)
  return (
    <div className={`${isShow ? 'd-flex' : 'd-none'} menu-item`}>
      <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
          <span className='menu-icon fs-2'>
            {' '}
            { getIcon(icon) }
          </span>
          <span className='menu-title' style={{ fontSize: '11pt' }}>{title}</span>
      </Link>
      {/* {children} */}
    </div>
  )
}

export {SidebarMenuItemTtc}
