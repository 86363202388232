import { Modal } from "react-bootstrap";
import { extractTime, getInitialName } from "../../../../_metronic/helpers";
import Moment from "react-moment";
import 'moment-timezone';
import { useAuth } from "../core/Auth";

type Props = {
    participants: Array<{
        id: number | null,
        date: string,
        detail_schedule_id: number | null,
        origin_company: string,
        company_type: string,
        contact_person: string,
        status_id: number | null,
        start_time: string,
        end_time: string,
        created_at: string,
        message: string,
        action_by: number
    }>,
    requestType: string,
    showModalNotif: boolean,
    isLoadingUpdate: boolean,
    handleUpdateNotif: (detail_schedule_id, status_id, notif_id) => void,
    handleCloseModalNotif: () => void,
}

function NotificationRequest({ participants, requestType, showModalNotif, isLoadingUpdate, handleUpdateNotif, handleCloseModalNotif }: Props) {
    const typeList = {
        get_request: {
            title: 'Received  Request',
            desc: 'Your appointment request from another attendees.',
            buttonColor: '#F7D06033',
            textColor: '#7e8299',
            status: 'Pending'
        },
        send_request: {
            title: 'Send Request',
            desc: 'Your request has been sent successfully',
            buttonColor: '#F7D06033',
            textColor: '#7e8299',
            status: 'Confirmed'
        },
        confirmed_meetings: {
            title: 'Send Request',
            desc: 'Your appointment request to another attendees.',
            buttonColor: 'rgba(65, 153, 248, 0.2)',
            textColor: 'rgba(24, 28, 50, 0.5)',
            status: 'Confirmed'
        },
        decline_request: {
            title: 'Rejected Request',
            desc: 'Your appointment request to another attendees.',
            buttonColor: 'rgba(242, 63, 108, 0.2)',
            textColor: 'rgba(24, 28, 50, 0.5)',
            status: 'Rejected'
        },
        cancelled_request: {
            title: 'Cancelled Request',
            desc: 'Your appointment request to another attendees.',
            buttonColor: 'rgba(242, 63, 108, 0.2)',
            textColor: 'rgba(24, 28, 50, 0.5)',
            status: 'Cancelled'
        }
    }

    const { currentUser } = useAuth()

    return (
        <>
            <Modal show={showModalNotif} onHide={handleCloseModalNotif}>
                <Modal.Header closeButton>
                    <div className='card-title text-center d-block w-100'>
                        <h3 className='card-label fw-bold text-dark'>{typeList[requestType].title}</h3>
                        <span className='text-muted mt-1 fw-semibold fs-7'>{typeList[requestType].desc}</span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {
                        participants.length ? participants.map((item, index) => {
                            const extract = extractTime(item.start_time, item.end_time, item.date)
                            return (
                                <>
                                    <div key={index} className='d-flex align-items-sm-center pb-3 pt-3' style={{ borderBottom: '2px solid #cdcdcd', borderBottomStyle: 'dashed' }}>
                                        <div className="symbol symbol-40px symbol-circle" data-bs-toggle="tooltip" title="Alan Warden">
                                            <span className="symbol-label fw-bold bg-warning text-inverse-warning">{
                                                getInitialName(item.origin_company)
                                            }</span>
                                        </div>
                                        <div className='d-flex align-items-center flex-row-fluid flex-wrap ps-4'>
                                            <div className='flex-grow-1 me-2'>
                                                <span className='text-gray-800 text-hover-primary fs-5 fw-bold'>
                                                    {item.origin_company}&nbsp;<span className='text-muted fw-semibold fs-7'>{item.contact_person}</span>
                                                </span>
                                                {/* <span className='text-muted fw-bold d-block fs-7'>{item.company_type}</span> */}
                                            </div>
                                            {requestType === 'get_request' && (
                                                <div className="d-flex flex-column text-center">
                                                    <span className="fw-bold fs-5">{<Moment date={extract.date} format="DD MMM YYYY"></Moment>}</span>
                                                    <span className="fs-7"> {extract.timeStart} &nbsp; - &nbsp; {extract.timeEnd}</span>
                                                </div>
                                            )}

                                            {(requestType === 'decline_request' || requestType === 'confirmed_meetings' || requestType === 'cancelled_request') && (
                                                <span className='badge badge-light fw-bold p4 my-2 p-2' style={{
                                                    backgroundColor: typeList[requestType].buttonColor,
                                                    color: typeList[requestType].textColor
                                                }}>{typeList[requestType].status}</span>
                                            )}
                                        </div>
                                    </div>

                                    {(requestType === 'get_request' && item.action_by !== currentUser?.id) && (
                                        <div className='d-flex justify-content-center mt-10'>
                                            {(isLoadingUpdate) && (
                                                <div className='d-flex justify-content-center'>
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                </div>
                                            )}

                                            {!isLoadingUpdate && (
                                                <>
                                                    <span
                                                        className="btn btn-sm btn-primary"
                                                        style={{ backgroundColor: '#F23F6C' }}
                                                        onClick={() => handleUpdateNotif(item.detail_schedule_id, 3, item.id)}>REJECT</span>
                                                    <span
                                                        className="btn btn-sm btn-primary ms-3"
                                                        style={{ backgroundColor: '#4199F8' }}
                                                        onClick={() => handleUpdateNotif(item.detail_schedule_id, 2, item.id)}>ACCEPT</span>
                                                </>
                                            )}
                                        </div>
                                    )}

                                    {(requestType === 'get_request' && item.action_by === currentUser?.id) && (
                                        <div className='d-flex justify-content-center mt-10'>
                                            {(isLoadingUpdate) && (
                                                <div className='d-flex justify-content-center'>
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                </div>
                                            )}

                                            {!isLoadingUpdate && (
                                                <span
                                                    className="btn btn-sm btn-primary"
                                                    style={{ backgroundColor: '#F23F6C' }}
                                                    onClick={() => handleUpdateNotif(item.detail_schedule_id, 4, item.id)}>CANCEL</span>
                                            )}
                                        </div>
                                    )}

                                    {((requestType === 'decline_request') && (item.action_by !== currentUser?.id)) && (
                                        <div className='d-flex justify-content-center mt-10'>
                                            {(isLoadingUpdate) && (
                                                <div className='d-flex justify-content-center'>
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                </div>
                                            )}

                                            {!isLoadingUpdate && (
                                                <button type="button" onClick={() => handleUpdateNotif(item.detail_schedule_id, 1, item.id)} className="btn btn-sm btn-primary me-3" style={{ backgroundColor: '#4199F8' }}>RE-REQUEST</button>
                                            )}
                                        </div>
                                    )}

                                    {((requestType === 'cancelled_request') && (item.action_by === currentUser?.id)) && (
                                        <div className='d-flex justify-content-center mt-10'>
                                            {(isLoadingUpdate) && (
                                                <div className='d-flex justify-content-center'>
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                </div>
                                            )}

                                            {!isLoadingUpdate && (
                                                <button type="button" onClick={() => handleUpdateNotif(item.detail_schedule_id, 1, item.id)} className="btn btn-sm btn-primary me-3" style={{ backgroundColor: '#4199F8' }}>RE-REQUEST</button>
                                            )}
                                        </div>
                                    )}
                                </>
                            )
                        }) : ''
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default NotificationRequest;