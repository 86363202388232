import { FC, useEffect, useState } from "react";
import { RiCloseLine, RiImageAddFill, RiInformationFill } from "react-icons/ri";
import { PageTitle } from "../../../_metronic/layout/core";
import FsLightbox from "fslightbox-react";
import Dropzone from "react-dropzone";
import { BASE_URL, GET_PRODUCT_URL, UPLOAD_PRODUCT_URL, getProducts, deleteProductsById } from "../../modules/auth/core/_requests";
import { useAuth } from "../../modules/auth";
import { Modal } from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar';
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ProductPage: FC = () => {
    const MySwal = withReactContent(Swal)
    const active = {
        borderBottom: '2px solid #3F98FF',
        fontWeight: 700,
        fontSize: '1.05rem'
    }

    const [toggler, setToggler] = useState(false);
    const [togglerIndex, setTogglerIndex] = useState<number>(0);
    const [showModalUpload, setShowModalUpload] = useState(false);
    const [progressUpload, setProgressUpload] = useState(0);

    const [thumbnails, setThumbnails] = useState([]);
    const [images, setImages] = useState([]);

    const dropFiles = (acceptedFiles) => {
        let formData = new FormData()
        setThumbnails([])
        setProgressUpload(0)

        if (acceptedFiles.length === 0) return;

        for (const file of acceptedFiles) {
            formData.append('images[]', file)
        }
        formData.append('participant_id', auth?.user.participant.id)

        async function uploadProducts() {
            try {
                await axios.post(UPLOAD_PRODUCT_URL, formData, {
                    onUploadProgress(progressEvent) {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor((loaded * 100) / total);
                        if (percent <= 100) {
                            setProgressUpload(percent)
                        }
                    }
                })
                MySwal.fire({
                    title: 'Success',
                    html: 'Image uploaded successfully',
                    icon: 'success'
                })
                setShowModalUpload(false)
                fetchProducts()
            } catch (error) {
                MySwal.fire({
                    title: 'Error',
                    html: 'Failed upload product',
                    icon: 'error'
                })
            }

        }
        uploadProducts();
        setImages(acceptedFiles);
        setThumbnails(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
    };

    const renderThumbnails = thumbnails.map((file: any) => (
        <div className='col-sm-3'
            key={file.name}
            style={{
                textAlign: 'center',
                borderRadius: 2,
                border: '1px solid #eaeaea',
                marginBottom: 8,
                marginRight: 8,
                padding: 4
            }}>
            <img
                src={file.preview}
                style={{ height: '100px' }}
                className="img-fluid img-thumbnail" alt={file.name}
                onLoad={() => { URL.revokeObjectURL(file.preview) }}></img>
        </div>
    ));


    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => thumbnails.forEach((file: any) => URL.revokeObjectURL(file.preview));
    }, [showModalUpload]);


    // fetch products
    const getResourceImage = (product) => {
        return product.reduce((temp, item) => { return [...temp, `${item.path_file}`] }, []);
    }

    const [products, setProducts] = useState<{
        id: number,
        name_product: string,
        path_file: string
    }[]>([])

    const { auth } = useAuth()

    const [loading, setLoading] = useState(false)
    async function fetchProducts() {
        try {
            setLoading(true)
            const response = await getProducts(auth?.user.participant.id)
            setProducts(response.data)
        } catch (error) {
            MySwal.fire({
                title: 'Error',
                html: 'Failed get product data',
                icon: 'error'
            })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchProducts();
    }, []);


    const removeProduct = (id) => {
        try {
            // (document.getElementById('kt_app_footer') as HTMLElement).style.position = 'fixed';
            // (document.getElementById('kt_app_footer') as HTMLElement).style.bottom = '0px';
            // (document.getElementById('kt_app_footer') as HTMLElement).style.width = '100%';
            // document.getElementById().style.position = 'fixed'
            setLoading(true)
            MySwal.fire({
                title: 'Attention',
                icon: 'info',
                html: 'Are you sure want to delete this file?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
            })
                .then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        const response = await deleteProductsById(id);
                        MySwal.fire({
                            title: 'Success',
                            html: 'Product removed successfully',
                            icon: 'success'
                        });
                        // (document.getElementById('kt_app_footer') as HTMLElement).style.removeProperty("position");
                        fetchProducts()
                        setTogglerIndex(0)
                    } else if (result.isDenied) {
                        // (document.getElementById('kt_app_footer') as HTMLElement).style.removeProperty("position");
                    }
                })

        } catch (error) {
            MySwal.fire({
                title: 'Error',
                html: 'Failed remove product data',
                icon: 'error'
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            {/* begin::Attendees List */}
            <div className='row'>
                <div className='col-xxl-8'>
                    <div className="alert alert-primary d-flex align-items-center p-2" role="alert">
                        <span className="fa-2x text-primary pe-4"><RiInformationFill /></span>
                        These are the products you have, you can upload or change the products you want to display.
                    </div>
                </div>
                <div className='col-xxl-4 justify-content-end d-flex align-items-center mb-10'>
                    <button onClick={() => { setShowModalUpload(true); setProgressUpload(0); setThumbnails([]) }} className="btn btn-sm btn-primary" style={{height: '40px'}}>Upload</button>
                </div>
                <div className='col-xxl-12 text-center'>
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </div>
                <div id="emptyProductsWrapper" className={`${products.length > 0 ? 'd-none' : 'd-block'}`}>
                    <div className="col-xxl-12">
                        <div className='text-center'>
                            <h3 className="fs-1 fw-bold">
                                The file you uploaded is empty. <br />
                                Please choose a file to upload!
                            </h3>
                            <p className="text-gray-600 mt-3">
                                Get your media files ready to share <br /> with another participants!
                            </p>
                            <img src="/ttc/illustration.png" className='img-fluid w-30' alt="attendees list illustrator" />
                        </div>
                    </div>
                </div>
                <div id="productsWrapper" className={`${products.length > 0 ? 'd-block' : 'd-none'}`}>
                    <div className='col-xxl-12'>
                        <div className="row row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-2">
                            {products.length ? products.map((item, index) => {
                                return (
                                    <div key={item.id} className="rounded mb-5">
                                        <div className="d-block overlay img-ttc-thumbnail" data-fslightbox="lightbox-hot-sales">
                                            <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-175px" style={{ backgroundImage: `url('${item.path_file}')` }}>
                                            </div>
                                            <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                                <i onClick={() => { setToggler(!toggler); setTogglerIndex(index) }} className="ki-outline ki-eye fs-3x text-white"></i>&nbsp;&nbsp;&nbsp;
                                                <i onClick={() => removeProduct(item.id)} className="ki-outline ki-trash fs-3x text-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : ''}
                        </div>
                        {/* <FsLightbox
                            toggler={toggler}
                            sources={getResourceImage(products)}
                            sourceIndex={togglerIndex}
                        onClose={() => setToggler(false)}
                        /> */}
                    </div>
                </div>
            </div>
            {/* end:Cancel Meeting */}

            {/* begin:Preview Image */}
            <Modal show={toggler} onHide={() => setToggler(!toggler)}>
                <Modal.Body>
                    <div className="position-relative">
                        <div className="d-flex w-100 justify-content-end position-absolute">
                            <div className="symbol symbol-30px symbol-circle" data-bs-toggle="tooltip">
                                <span className="symbol-label fw-bold bg-secondary text-inverse-warning">
                                    <span className="fs-1" onClick={() => setToggler(!toggler)}><RiCloseLine /></span>
                                </span>
                            </div>
                        </div>
                        {/* begin::Item */}
                        <div className="d-flex w-100 justify-content-center align-items-center">
                            <img className="img-fluid" alt="preview" src={products.length ? products[togglerIndex].path_file : ''} />
                        </div>
                    </div>
                    {/* end::Item */}
                </Modal.Body>
            </Modal>
            {/* end:Preview Image */}
            <Modal show={showModalUpload} onHide={() => setShowModalUpload(false)}>
                <Modal.Header closeButton>
                    {/* begin::Header */}
                    <div className='card-title text-center d-block w-100'>
                        <div className="upload-title text-center">
                            <h3 className="text-bold">Upload Photo Product</h3>
                            <p>
                                Let's upload your awesome photos product here!
                            </p>
                        </div>
                    </div>
                    {/* end::Header */}
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            {/* begin::Dropzone file */}
                            <Dropzone
                                accept={{ 'image/*': ['.jpeg', '.png'] }}
                                maxSize={2000000}
                                disabled={progressUpload > 0}
                                onDrop={(acceptedFiles) => dropFiles(acceptedFiles)}>
                                {({ getRootProps, getInputProps }) => (
                                    <section className='w-100 mb-8' style={{ border: '3px solid #cdcdcc', borderStyle: 'dashed' }}>
                                        <div {...getRootProps()} style={{ height: '250px' }}
                                            className='d-flex flex-column w-100 justify-content-center align-items-center text-center'>
                                            <input {...getInputProps()} />
                                            <p>
                                                <span className="fa-4x text-primary"><RiImageAddFill /></span>
                                            </p>
                                            <p>
                                                Let's show off your [media]! <br />
                                                Drag and drop or click to upload multiple photo.<br />
                                                Please upload a PNG, JPG, or JPEG file. <br />
                                            </p>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                            <ProgressBar now={progressUpload} label={`${progressUpload}%`} />
                            <div id="thumnail-img" className={`row mt-4 ${thumbnails.length > 0 ? 'd-flex' : 'd-none'}`}>
                                {renderThumbnails}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

const ProductsWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Products</PageTitle>
            <ProductPage />
        </>
    )
}

export { ProductsWrapper, ProductPage };