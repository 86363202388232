/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes, useLocation} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  const location = useLocation();

  useEffect(() => {
    if(location.pathname !== '/auth/registration') {
      (document.getElementsByClassName('login-ttc') as HTMLCollectionOf<HTMLElement>)[0].style.height = '100vh'
    } else {
      (document.getElementsByClassName('login-ttc') as HTMLCollectionOf<HTMLElement>)[0].style.height = '100%'
    }
  }, [location.pathname])

  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div
      className='auth-wrapper d-flex bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
    >
      <div className='banner-ttc'>
        <img alt='banner' className='img-fluid' src='/ttc/banner.png'/>
      </div>
      <div className='login-ttc'>
        {/* begin::Content */}
        <div className='d-flex flex-center p-10 pb-lg-20 w-100'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-xl-20 p-lg-15 mx-auto' style={{ border: '1px solid #cdcdcd', paddingTop: '30px' }}>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Content */}
      </div>
      {/* begin::Footer */}
      {/* <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='#' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div>
      </div> */}
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
