/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo, createContext } from 'react'
import { useQuery } from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  // PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../_metronic/helpers'
import { getReport } from '../../../modules/auth/core/_requests'
import { Report } from './_models'
import { useQueryRequest } from './QueryRequestProvider'
import { AxiosResponse } from 'axios'

// const QueryResponseContext = createResponseContext<Report>(initialQueryResponse)
type PaginationLink = {
  url: string | null,
  label: string,
  active: boolean
}

type PaginationState = {
  current_page: number | string,
  data?: Array<Report>
  first_page_url?: string | null,
  from?: string | number,
  last_page?: string | number,
  last_page_url?: string,
  links: Array<PaginationLink>
  next_page_url?: string | null,
  path?: string | null,
  per_page: number | null,
  prev_page_url?: string | null,
  to?: number | string,
  total?: number | string
}

const QueryResponseContext = createContext<{
  response?: PaginationState | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}>({ refetch: () => { }, isLoading: false, query: '', response: {
  current_page: 1, per_page: 10, links: []
} })

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { state } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  let {
    isFetching,
    refetch,
    data: response,
  } = useQuery(`${QUERIES.USERS_LIST}-${query}`, () => {
    return getReport(query)
      .then((d: AxiosResponse<PaginationState>) => d.data)
    // console.log('TEST', result)
    // return result
    // await setTimeout(() => {
    // return {
    //   "data": {
    //     "current_page": 1,
    //     "data": [
    //       {
    //         "company_name": "dummy5",
    //         "location": "5",
    //         "company_type": "1",
    //         "getting_request": 3,
    //         "send_request": 0,
    //         "confirmed": 0,
    //         "declined": 0
    //       },
    //       {
    //         "company_name": "dummy5",
    //         "location": "5",
    //         "company_type": "1",
    //         "getting_request": 3,
    //         "send_request": 0,
    //         "confirmed": 0,
    //         "declined": 0
    //       },
    //       {
    //         "company_name": "dummy5",
    //         "location": "5",
    //         "company_type": "1",
    //         "getting_request": 3,
    //         "send_request": 0,
    //         "confirmed": 0,
    //         "declined": 0
    //       },
    //       {
    //         "company_name": "PT. Intermilano",
    //         "location": "1",
    //         "company_type": "Importers",
    //         "getting_request": 0,
    //         "send_request": 0,
    //         "confirmed": 0,
    //         "declined": 0
    //       },
    //       {
    //         "company_name": "PT GK 10",
    //         "location": "1",
    //         "company_type": "1",
    //         "getting_request": 0,
    //         "send_request": 0,
    //         "confirmed": 0,
    //         "declined": 0
    //       },
    //       {
    //         "company_name": "PT Dummy",
    //         "location": "2",
    //         "company_type": "1",
    //         "getting_request": 0,
    //         "send_request": 0,
    //         "confirmed": 0,
    //         "declined": 0
    //       }
    //     ],
    //     "first_page_url": "http://172.16.1.42:8081/api/report-participant?page=1",
    //     "from": 1,
    //     "last_page": 1,
    //     "last_page_url": "http://172.16.1.42:8081/api/report-participant?page=1",
    //     "links": [
    //       {
    //         "url": null,
    //         "label": "&laquo; Previous",
    //         "active": false
    //       },
    //       {
    //         "url": "http://172.16.1.42:8081/api/report-participant?page=1",
    //         "label": "1",
    //         "active": true
    //       },
    //       {
    //         "url": null,
    //         "label": "Next &raquo;",
    //         "active": false
    //       }
    //     ],
    //     "next_page_url": null,
    //     "path": "http://172.16.1.42:8081/api/report-participant",
    //     "per_page": 10,
    //     "prev_page_url": null,
    //     "to": 6,
    //     "total": 6
    //   }
    // }

    // }, 1000)
  },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  )
  // console.log('test response', response?.data.data)

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query }}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  // type PaginationState = {
  //   current_page: number
  //   per_page: number | 10 | 30 | 50 | 100
  //   links?: Array<{ label: string; active: boolean; url: string | null; page?: number | null }>
  // }

  const defaultPaginationState: PaginationState = {
    links: [],
    per_page: 10,
    current_page: 1
  }

  const { response } = useQueryResponse()

  if (!response) {
    return defaultPaginationState
  }

  return response;
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
