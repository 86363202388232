import { Column } from "react-table"
import { Report } from "../_models"
import { ReportCustomHeader } from "./ReportCustomHeader"

const reportColumns: ReadonlyArray<Column<Report>> = [
    {
        Header: (props) => <ReportCustomHeader tableProps={props} title='Company Name' className='min-w-125px'/>,
        id: 'company_name',
        accessor: 'company_name'
    },
    // {
    //     Header: (props) => <ReportCustomHeader tableProps={props} title='Location' className='min-w-125px'/>,
    //     id: 'location',
    //     accessor: 'location'
    // },
    // {
    //     Header: (props) => <ReportCustomHeader tableProps={props} title='Customer Type' className='min-w-125px'/>,
    //     id: 'company_type',
    //     accessor: 'company_type'
    // },
    {
        Header: (props) => <ReportCustomHeader tableProps={props} title='Getting Request' className='min-w-125px'/>,
        id: 'getting_request',
        accessor: 'getting_request'
    },
    {
        Header: (props) => <ReportCustomHeader tableProps={props} title='Send Request' className='min-w-125px'/>,
        id: 'send_request',
        accessor: 'send_request'
    },
    {
        Header: (props) => <ReportCustomHeader tableProps={props} title='Confirmed' className='min-w-125px'/>,
        id: 'confirmed_request',
        accessor: 'confirmed'
    },
    {
        Header: (props) => <ReportCustomHeader tableProps={props} title='Declined Req' className='min-w-125px'/>,
        id: 'declined_request',
        accessor: 'declined'
    }
]

export { reportColumns }