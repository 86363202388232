import clsx from 'clsx'
import { createProfileImage, toAbsoluteUrl } from '../../../helpers'
import { RiNotification3Line, RiNotification3Fill } from "react-icons/ri";
import { HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher } from '../../../partials'
import { useLayout, usePageData } from '../../core'
import { useAuth } from '../../../../app/modules/auth';
import { useEffect, useState } from 'react';

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'fs-1'

const Navbar = () => {
  const { config } = useLayout()
  const { isNewNotif, setIsNewNotif } = usePageData()
  const { currentUser } = useAuth()

  const [profileImage, setProfileImage] = useState<{
    company_name: string | undefined
    profile_image: string | undefined
  }>({
    company_name: currentUser?.participant?.company_name,
    profile_image: currentUser?.participant?.profile_image
  })

  useEffect(() => {
    setProfileImage({
      company_name: currentUser?.participant?.company_name,
      profile_image: currentUser?.participant?.profile_image
    })
  }, [currentUser])

  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
          onClick={() => setIsNewNotif(false)}
        >
          <div className={`fs-1 ${isNewNotif ? 'd-none' : 'd-block'}`}>
            <RiNotification3Line />
          </div>
          <div className={`fs-1 ${isNewNotif ? 'd-block' : 'd-none'} text-danger`}>
            <RiNotification3Fill />
          </div>
        </div>
        <HeaderNotificationsMenu />
      </div>

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {createProfileImage('40px', profileImage)}
        </div>
        <HeaderUserMenu />
      </div>

      {/* {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )} */}
    </div>
  )
}

export { Navbar }
