/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
  className: string
}

const ListsWidget3: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <div className='d-flex flex-column justify-content-center'>
          <h3 className='card-title fw-bold text-dark'>Schedule Today</h3>
          <span>Total 22 meetings</span>
        </div>
        <div className='card-toolbar'>
          <h2 className='fw-bold text-dark'>June 2023</h2>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        <div className="row align-items-start mt-2 mb-4">
          <div className="col text-center">
            <span className="btn btn-sm fw-bold btn-primary ps-10 pe-10 rounded-pill" style={{ backgroundColor: '#F1416C' }}>
              Monday <br />
              22
            </span>
          </div>
          <div className="col text-center">
            <span className="btn btn-sm fw-bold btn-primary ps-10 pe-10 rounded-pill" style={{ backgroundColor: '#F1416C' }}>
              Monday <br />
              22
            </span>
          </div>
          <div className="col text-center">
            <span className="btn btn-sm fw-bold btn-primary ps-10 pe-10 rounded-pill" style={{ backgroundColor: '#F1416C' }}>
              Monday <br />
              22
            </span>
          </div>
        </div>
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-8'>
          {/* begin::Bullet */}
          <span className='bullet bullet-vertical h-60px bg-success'></span>
          {/* end::Bullet */}
          {/* begin::Description */}
          <div className='flex-grow-1 ps-3'>
            <span className='fw-bold fs-4'>10:30 - 11:00<span className='text-muted fw-bold fs-8'>&nbsp;AM</span></span>
            <span className='text-gray-700 text-hover-primary fw-bold fs-6 d-block'>
              Create FireStone Logo
            </span>
            <span className='text-muted fw-semibold d-block fs-8'>Invited By <span className='text-primary'>ICP</span></span>
          </div>
          {/* end::Description */}
          <span className='badge badge-light-success fs-8 fw-bold'>New</span>
        </div>
        {/* end:Item */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget3}
