export interface MyProfile {
    companyName: string
    location: string
    contactPerson: string
    contactNumber: string
    companyWebsite: string
    companySite: string
    customerType: string
    description: string
}

export const initialMyProfile: MyProfile = {
    companyName: '',
    location: '',
    contactPerson: '',
    contactNumber: '',
    companyWebsite: '',
    companySite: '',
    customerType: '',
    description: '',
}

export interface UpdatePassword {
    currentPassword: string
    newPassword: string
    passwordConfirmation: string
}

export const initialPassword: UpdatePassword = {
    currentPassword: '',
    newPassword: '',
    passwordConfirmation: '',
}