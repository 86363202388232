import { Modal } from "react-bootstrap";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";
import { createProfileImage, getInitialName } from "../../../../_metronic/helpers";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

function GettingRequestDsb({ participants, loadingUpdate = false, isAdmin = false, showModalGet, handleCloseModalGet, handleUpdateRequest }) {
    const selectedAttendee = useRef<number | null>(null);

    const typeList = {
        getting_request: {
            title: 'Send Request',
            desc: 'Your request has been sent successfully',
            buttonColor: '#F7D06033',
            textColor: '#7e8299',
            status: 'Pending'
        },
        confirmed_meetings: {
            title: 'Confirmed Request',
            desc: 'Your confirmed meetings',
            buttonColor: '#198754',
            textColor: '#ffffff',
            status: 'Confirmed'
        },
        declined_request: {
            title: 'Declined Request',
            desc: 'Your declained request',
            buttonColor: '#dc3545',
            textColor: '#ffffff',
            status: 'Declined'
        }
    }

    useEffect(() => {
        if (loadingUpdate) selectedAttendee.current = null
    }, [loadingUpdate])
    return (
        <>
            <Modal show={showModalGet} onHide={handleCloseModalGet}>
                <Modal.Header closeButton>
                    {/* begin::Header */}
                    <div className='card-title text-center d-block w-100'>
                        <h3 className='card-label fw-bold text-dark'>Received  Request</h3>
                        <span className='text-muted mt-1 fw-semibold fs-7'>You received request from other participants.</span>
                    </div>
                    {/* end::Header */}
                </Modal.Header>
                <Modal.Body>
                    <div className="scroll scroll-pull" data-scroll="true" data-wheel-propagation="true" style={{ height: '400px' }}>
                        {/* begin::Item */}
                        {
                            participants.length ? participants.map((item, index) => {
                                return (
                                    <div key={index} className='d-flex align-items-sm-center pb-3' style={{ borderBottom: '2px solid #cdcdcd', borderBottomStyle: 'dashed', marginBottom: '10px' }}>
                                        {createProfileImage('40px', item)}
                                        <div className='d-flex align-items-center flex-row-fluid flex-wrap ps-4'>
                                            <div className='flex-grow-1 me-2'>
                                                {
                                                    item.status_label === 'getting_request' && (
                                                        <Link state={{ 
                                                            participant_id: item.participant_id || 0, 
                                                            status_id: item.status_id,
                                                            name: item.company_name
                                                            }} to={`/attendees`}>
                                                            <span className='text-gray-800 text-hover-primary fs-5 fw-bold'>
                                                                {item.company_name}&nbsp;<span className='text-muted fw-semibold fs-7'>
                                                                    <span className='text-muted fw-semibold fs-7'>
                                                                        {item.start_time || '00:00'} - {item.end_time || '00:00'}
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    )
                                                }

                                                {
                                                    item.status_label !== 'getting_request' && (
                                                        <span className='text-gray-800 fs-5 fw-bold'>
                                                            {item.company_name}&nbsp;<span className='text-muted fw-semibold fs-7'>
                                                                <span className='text-muted fw-semibold fs-7'>
                                                                    {item.start_time || '00:00'} - {item.end_time || '00:00'}
                                                                </span>
                                                            </span>
                                                        </span>
                                                    )
                                                }

                                                {/* <span className='text-muted fw-bold d-block fs-7'>{item.company_type}</span> */}
                                            </div>
                                            <div className={`${isAdmin ? 'd-none' : 'd-flex'}`}>
                                                {
                                                    item.status_label !== 'getting_request' && (
                                                        <>
                                                            <span className='badge badge-light fw-bold my-2 p-2' style={{
                                                                backgroundColor: typeList[item.status_label].buttonColor,
                                                                color: typeList[item.status_label].textColor
                                                            }}>{typeList[item.status_label].status}</span>
                                                        </>
                                                    )
                                                }
                                                {(loadingUpdate && selectedAttendee.current === item.id && item.status_label === 'getting_request') && (
                                                    <div className='d-flex justify-content-center'>
                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                                            Please wait...
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    </div>
                                                )}
                                                {!(selectedAttendee.current === item.id) && item.status_label === 'getting_request' && (
                                                    <>
                                                        <span onClick={() => { handleUpdateRequest(item.detail_schedule_id, 3); selectedAttendee.current = item.id }} className='badge badge-light fw-bold text-white my-2 p-2 fs-2 me-2' style={{ backgroundColor: '#F23F6C' }}>
                                                            <RiCloseLine />
                                                        </span>
                                                        <span onClick={() => { handleUpdateRequest(item.detail_schedule_id, 2); selectedAttendee.current = item.id }} className='badge badge-light fw-bold text-white my-2 p-2 fs-2' style={{ backgroundColor: '#4199F8' }}>
                                                            <RiCheckLine />
                                                        </span>
                                                    </>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                )
                            }) : <div className="text-center w-100">Empty Data</div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default GettingRequestDsb;