import DatePicker from "react-datepicker";
import moment from "moment";
import { KTIcon } from "../../../../_metronic/helpers";
import { ButtonProps } from "react-bootstrap";
import { FC, forwardRef, useEffect, useState } from "react";
import { useQueryRequest } from "./QueryRequestProvider";
import { getLocation, postExportReport } from "../../../modules/auth/core/_requests";
import Select from 'react-select'

export function FilterTable() {
    const [startDate, setStartDate] = useState(null);
    const CustomInputDate = forwardRef<HTMLButtonElement, ButtonProps>(({ value, onClick }, ref) => (
        <div className="d-flex d-flex align-items-center">
            <span style={{ position: 'absolute', right: '10px' }}>
                {value && (
                    <span onClick={() => handleChangeDate(null)}><KTIcon iconName='cross' className='fs-1' /></span>
                )}
                <KTIcon iconName='calendar' className='fs-1' />
            </span>
            <button className="form-control form-control-solid" style={{ width: '180px', textAlign: 'left' }} onClick={onClick} ref={ref}>
                {(value ? value : 'Select a date')}</button>
        </div>
    ));

    const { state, updateState } = useQueryRequest()

    const [locationOptions, setLocationOptions] = useState<Array<{
        label: string,
        options: Array<{}>
    }>>([
        {
            label: '',
            options: []
        }
    ])

    useEffect(() => {
        async function fetchLocation() {
            const response = await getLocation();
            setLocationOptions(response.data);
        }
        fetchLocation();
    }, [])

    const handleChangeDate = (date) => {
        setStartDate(date)
        updateState({ date: date ? moment(date).format('YYYY-MM-DD') : undefined, current_page: 1, per_page: 10 })
    }

    const handleChangeLocation = (location) => {
        updateState({ district_id: location?.value, current_page: 1, per_page: 10 })
    }

    const handleChangeType = (type) => {
        updateState({ customer_type: type?.value, current_page: 1, per_page: 10 })
    }

    const handleExport = function () {
        const fileName = 'participant_report_' + moment(new Date()).format('DD-MM-YYYY')

        postExportReport({
            district_id: state.district_id,
            customer_type: state.customer_type,
            date: state.date
        })
            .then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${fileName}.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
    }

    const customerOptions = [
        {
            value: '1',
            label: 'Importers'
        },
        {
            value: '2',
            label: 'Distributors'
        },
        {
            value: '3',
            label: 'Suppliers'
        }

    ];

    return (
        <div className='card-header border-0 pt-6'>
            <div className='card-title w-100'>
                {/* <div className="row w-100">
                    <div className="col-sm-12 col-md-6 col-lg-3 mt-4">
                        <DatePicker
                            placeholderText={'Please select a date'}
                            selected={startDate}
                            onChange={(date) => handleChangeDate(date)}
                            customInput={<CustomInputDate />}
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 mt-4">
                        <Select
                            placeholder="Select Location"
                            options={locationOptions}
                            isClearable={true}
                            onChange={handleChangeLocation}
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 mt-4">
                        <Select
                            placeholder="Select Customer Type"
                            options={customerOptions}
                            isClearable={true}
                            onChange={handleChangeType}
                        />
                    </div>
                    <div className="col-sm-12  col-md-6 col-lg-3 d-flex justify-content-end mt-4">
                        <button type='button' onClick={() => handleExport()} className='btn btn-light-primary me-3'>
                            <KTIcon iconName='exit-up' className='fs-2' />
                            Export
                        </button>
                    </div>
                </div> */}
                <div className="d-flex justify-content-between w-100">
                    <div>
                        <DatePicker
                            placeholderText={'Please select a date'}
                            selected={startDate}
                            onChange={(date) => handleChangeDate(date)}
                            customInput={<CustomInputDate />}
                        />
                    </div>
                    <div>
                        <button type='button' onClick={() => handleExport()} className='btn btn-light-primary me-3'>
                            <KTIcon iconName='exit-up' className='fs-2' />
                            Export
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}