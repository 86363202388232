import moment from 'moment';

export function extractTime(rawTimeStart, rawTimeEnd, rawDate) {
    let result = {
        time: rawTimeStart.substr(0, 2),
        date: rawDate.substr(0, 10),
        isTime30: rawTimeStart.substr(3, 2) === '30',
        dateTime: rawDate.substr(0, 10) + ' ' + rawTimeStart,
        timeStart: '',
        timeEnd: '',
        timeStartAm: '',
        timeEndAm: '',
        timeStartWithMeridiem: '',
        timeEndWithMeridiem: '',
        timeStartWith24: '',
        timeEndWith24: ''
    }

    // const nextHour = (parseInt(result.time) + 1);

    // result.timeStart = result.time + (result.isTime30 ? ':30' : ':00')
    // result.timeEnd = result.isTime30 ? (nextHour > 9 ? '' : '0') + nextHour + ':00' : result.time + ':30'
    result.timeStart = rawTimeStart.substr(0, 5)
    result.timeEnd = rawTimeEnd.substr(0, 5)
    result.timeStartAm = moment(`${result.date} ${result.timeStart}`).format('hh:mm')
    result.timeEndAm = moment(`${result.date} ${result.timeEnd}`).format('hh:mm')
    result.timeStartWithMeridiem = moment(`${result.date} ${result.timeStart}`).format('DD MMMM YYYY hh:mm A')
    result.timeEndWithMeridiem = moment(`${result.date} ${result.timeEnd}`).format('DD MMMM YYYY hh:mm A')
    result.timeStartWith24 = moment(`${result.date} ${result.timeStart}`).format('DD MMMM YYYY HH:mm')
    result.timeEndWith24 = moment(`${result.date} ${result.timeEnd}`).format('DD MMMM YYYY HH:mm')

    return result;
}

export const getInitialName = (name) => {
    let initial = '';
    if (name) {
        const initialName = name.split(' ');
        initial = initialName[0][0] + (initialName.length > 1 ? initialName[1][0] : '');
    }

    return initial;
}

export const createProfileImage = (size, data) => {
    return (
        <div className={`symbol symbol-${size} symbol-circle`} data-bs-toggle="tooltip" title={ data.company_name } >
            <span className="symbol-label fw-bold bg-warning text-inverse-warning" >
                {
                   !data.profile_image && (getInitialName(data.company_name))
                }

                {
                    data.profile_image && (<img src={ data.profile_image } style = {{ width: size, height: size, borderRadius: '50%' }} alt = { data.company_name } />)
                }
            </span>
        </div>
    )
}