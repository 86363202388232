import { FC, LegacyRef, MouseEventHandler, PropsWithChildren, PropsWithRef, forwardRef, useEffect, useState } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { KTCard } from "../../../_metronic/helpers";
import { ReportTable } from "./Report/ReportTable";
import { QueryRequestProvider} from "./Report/QueryRequestProvider";
import { FilterTable } from "./Report/FilterTable";

import "react-datepicker/dist/react-datepicker.css";
import { QueryResponseProvider } from "./Report/QueryResponseProvider";


const ReportPage: FC = () => {
    return (
        <QueryRequestProvider>
            <QueryResponseProvider>
                <KTCard>
                    <FilterTable />
                    <ReportTable />
                </KTCard>
            </QueryResponseProvider>
        </QueryRequestProvider >
    )
}

const ReportAdminWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Report</PageTitle>
            <ReportPage />
        </>
    )
}

export { ReportAdminWrapper };
