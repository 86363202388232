import { FC, useEffect, useState } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers";
import { IProfileDetails, profileDetailsInitValues as initialValuess } from "../../modules/accounts/components/settings/SettingsModel";
import { MyProfile, UpdatePassword, initialPassword, initialMyProfile as initialValues } from "./ProfileModel";
import { getLocation, getMyProfile, postUpdateMyPassword, postUpdateMyProfile } from "../../modules/auth/core/_requests";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Select from 'react-select'
import { AuthModel, UserModel, useAuth } from "../../modules/auth";
import { RiCloseLine, RiImageAddFill } from "react-icons/ri";
import Dropzone from "react-dropzone";


const profileDetailsSchema = Yup.object().shape({
    companyName: Yup.string().required('Company name is required'),
    location: Yup.object().required('Country is required'),
    contactPerson: Yup.string().required('Contact person is required'),
    contactNumber: Yup.string().required('Contact number is required'),
    companyWebsite: Yup.string(),
    // customerType: Yup.object().required('Customer type is required'),
    description: Yup.string().required('Description is required').max(500, 'Maximal character is 500')
})

const passwordFormValidationSchema = Yup.object().shape({
    currentPassword: Yup.string(),
    // newPassword: Yup.string()
    //     .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*?[~!@#$%^&*()_+|=\-\\{}[\];’:”<>?/,.])([\w\~!@#$%^&*()_+|=\-\\{}[\];’:”<>?/,.]{6,50})$/,
    //         'Use 6 or more characters with a mix of letters, numbers & symbols.'),
    newPassword: Yup.string()
        .min(3, 'Minimum 3 character')
        // .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    passwordConfirmation: Yup.string()
        .min(3, 'Minimum 3 character')
        // .max(50, 'Maximum 50 symbols')
        .required('Password is required')
        .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
})

const ProfilePage: FC = () => {
    const MySwal = withReactContent(Swal)
    const [loading, setLoading] = useState(false);
    const customerOptions = [
        {
            value: '1',
            label: 'Importers'
        },
        {
            value: '2',
            label: 'Distributors'
        },
        {
            value: '3',
            label: 'Suppliers'
        }

    ];

    const [data, setData] = useState<MyProfile>(initialValues)
    const updateData = (fieldsToUpdate: Partial<MyProfile>): void => {
        const updatedData = Object.assign(data, fieldsToUpdate)
        setData(updatedData)
    }

    const { auth, currentUser, setCurrentUser, logout, saveAuth } = useAuth()
    const isParticipant = auth?.user.role_id === 2

    const formik = useFormik<MyProfile>({
        initialValues,
        validationSchema: profileDetailsSchema,
        onSubmit: (values) => {
            setLoading(true)
            const rawLocation = Object.assign({}, values.location)
            // const rawCustomerType = Object.assign({}, values.customerType)
            let payload = {
                company_name: values.companyName,
                contact_number: values.contactNumber,
                contact_person: values.contactPerson,
                company_website: values.companyWebsite,
                description: values.description,
                // province_id: rawLocation['value'] || '',
                country_id: rawLocation['value'] || '',
                // company_type_id: rawCustomerType['value'] || '',
                email: auth?.user.email
            }

            const formData = new FormData();

            for (const img of images) {
                formData.append('images', img)
            }

            for (const p in payload) {
                formData.append(p, payload[p])
            }

            postUpdateMyProfile(formData)
                .then((response) => {
                    MySwal.fire({
                        title: 'Success',
                        html: "Data successfully updated!",
                        icon: 'success'
                    })
                    fetchMyProfile();
                })
                .catch((error) => {
                    const dt = error.response.data
                    const hasError = dt.hasOwnProperty('errors');
                    const attribute = Object.keys(dt.errors)

                    let errorMsg = "Can't update profile data"
                    if (hasError) errorMsg = attribute.length ? dt.errors[attribute[0]] : errorMsg
                    MySwal.fire({
                        title: 'Error',
                        html: errorMsg,
                        icon: 'error',
                    })

                    fetchMyProfile();

                })
                .finally(() => {
                    setLoading(false)
                })
        },
    })

    const [thumbnails, setThumbnails] = useState<any[]>([]);
    const [imagesError, setImagesError] = useState('');

    const [images, setImages] = useState<any[]>([]);
    const dropFiles = (acceptedFiles, fileRejections) => {
        setImagesError('');

        let mergedFile = [...images, ...acceptedFiles]
        if (mergedFile.length > 1) {
            setImagesError(`Too many files, maximal 1 file`)
            return;
        }
        setImages(mergedFile);

        let mapThumbnails = mergedFile.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }))

        setThumbnails(mapThumbnails);

        fileRejections.forEach((file) => {
            file.errors.forEach((err) => {
                if (err.code === "file-too-large") {
                    setImagesError(`File is too big, maximal size is 2 MB`);
                }

                if (err.code === "file-invalid-type") {
                    setImagesError(`Invalid file type`);
                }

                if (err.code === 'too-many-files') {
                    setImagesError(`Too many files, maximal 1 file`)
                }
            });
        });
    };

    const renderThumbnails = thumbnails.map((file: any, index) => (
        <div className='col-sm-12 text-center'
            key={index}
            style={{
                borderRadius: 2,
                marginBottom: 8,
                marginRight: 8,
                padding: 4,
                position: 'relative',
            }}>
            <div className="symbol symbol-30px symbol-circle" data-bs-toggle="tooltip"
                style={{ position: 'absolute', top: '0px', right: '0px' }}>
                <span className="symbol-label fw-bold bg-dark text-inverse-warning">
                    <span className="fs-1" onClick={() => setImages([])}><RiCloseLine /></span>
                </span>
            </div>
            <img
                src={file.preview}
                className="img-thumbnail" alt={file.name}
                style={{ width: '250px', height: '250px' }}
                onLoad={() => { URL.revokeObjectURL(file.preview) }}></img>
        </div>
    ));


    /* Password Form */

    const [passwordUpdateData, setPasswordUpdateData] = useState<UpdatePassword>(initialPassword)
    const [showPasswordForm, setPasswordForm] = useState<boolean>(false)
    const [loading2, setLoading2] = useState(false)

    const formik2 = useFormik<UpdatePassword>({
        initialValues: {
            ...passwordUpdateData,
        },
        validationSchema: passwordFormValidationSchema,
        onSubmit: (values) => {
            setLoading2(true)
            const payload = {
                current_password: values.currentPassword,
                new_password: values.newPassword,
                new_password_confirmation: values.passwordConfirmation,

            }

            postUpdateMyPassword(payload)
                .then((response) => {
                    MySwal.fire({
                        title: 'Success',
                        html: "Data successfully updated.<br/>Please login with new password!",
                        icon: 'success'
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            setPasswordUpdateData(initialPassword)

                            for (const key in initialPassword) {
                                formik2.setFieldValue(key, '')
                            }

                            setTimeout(() => {
                                logout(true)
                                document.location.reload()
                            }, 2000)
                        }
                    })

                })
                .catch((error) => {
                    const dt = error.response.data
                    const hasError = dt.hasOwnProperty('errors');

                    MySwal.fire({
                        title: 'Error',
                        html: hasError ? dt.message : "Data failed to update!",
                        icon: 'error'
                    })
                })
                .finally(() => {
                    setLoading2(false)
                    setPasswordForm(false)
                })

            // setTimeout((values) => {
            //     setPasswordUpdateData(values)
            //     setLoading2(false)
            //     setPasswordForm(false)
            // }, 1000)
        },
    })

    const resetPasswordForm = () => {
        formik2.resetForm()
    }

    const [locationOptions, setLocationOptions] = useState<Array<{
        label: string,
        options: Array<{}>
    }>>([
        {
            label: '',
            options: []
        }
    ])

    const handleChangeLocation = (selected) => {
        formik.setTouched({ ...formik.touched, location: true });
        if (!selected) {
            formik.setErrors({ location: "Country is required" })
        }
        formik.setFieldValue('location', selected)
    }

    const handleChangeType = (selected) => {
        formik.setTouched({ ...formik.touched, customerType: true });
        if (!selected) {
            formik.setErrors({ customerType: "Customer type is required" })
        }
        formik.setFieldValue('customerType', selected)
    }

    const fetchMyProfile = () => {
        getMyProfile()
            .then((response) => {
                const dt = response.data.data

                const mapData = {
                    companyName: dt.company_name,
                    customerType: {
                        value: dt.type.id,
                        label: dt.type.name
                    },
                    location: {
                        value: dt.country.id,
                        label: dt.country.name,
                    },
                    contactPerson: dt.contact_person,
                    contactNumber: dt.contact_number,
                    companyWebsite: dt.company_website,
                    description: dt.description,
                }


                const user: UserModel = {
                    id: currentUser?.id,
                    name: currentUser?.name,
                    email: currentUser?.email,
                    role_id: currentUser?.role_id,
                    is_email_verified: currentUser?.is_email_verified,
                    participant: {
                        id: currentUser?.participant.id,
                        user_id: currentUser?.participant.user_id,
                        company_name: dt.company_name,
                        location: currentUser?.participant.location,
                        country_id: currentUser?.participant.country_id,
                        contact_number: currentUser?.participant.contact_number,
                        contact_person: currentUser?.participant.contact_person,
                        company_type: currentUser?.participant.company_type,
                        company_website: currentUser?.participant.company_website,
                        profile_image: dt.profile_image,
                        description: currentUser?.participant.description,
                        created_at: currentUser?.participant.created_at,
                        updated_at: currentUser?.participant.updated_at,
                    }
                }

                setCurrentUser(user)

                let authData: AuthModel = {
                    token: auth ? auth.token : '',
                    user: user
                }

                saveAuth(authData)

                if (dt.profile_image) {
                    setImages([{ preview: dt.profile_image }])
                    setThumbnails([{ preview: dt.profile_image }])
                }

                for (const key in mapData) {
                    formik.setFieldValue(key, mapData[key])
                }
            })
            .catch((error) => {
                MySwal.fire({
                    title: 'Error',
                    html: "Can't fetch profile data",
                    icon: 'error'
                })
            })
    }

    useEffect(() => {
        if (formik.values.contactNumber) {
            let newValue = formik.values.contactNumber.replace(/\D/g, '')
            formik.setFieldValue('contactNumber', newValue.trim())
        }

        // if (formik.values.contactPerson) {
        //     let cpValue = formik.values.contactPerson.replace(/[^a-zA-Z ]/g, '')
        //     cpValue = cpValue.replace(/^\s+|\s+$/g, '')
        //     formik.setFieldValue('contactPerson', cpValue)
        // }
    }, [formik.values.contactNumber]) //, formik.values.contactPerson])

    useEffect(() => {
        getLocation()
            .then((response) => {
                setLocationOptions(response.data);
            })
            .catch((error) => {
                MySwal.fire({
                    title: 'Error',
                    html: "Can't fetch location data",
                    icon: 'error'
                })
            })

        fetchMyProfile()
    }, [])

    return (
        <>
            { isParticipant && (
                <div className='card mb-5 mb-xl-5'>
                    <div
                        className='card-header border-0 cursor-pointer'
                        role='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_account_profile_details'
                        aria-expanded='true'
                        aria-controls='kt_account_profile_details'
                    >
                        <div className='card-title m-0'>
                            <h3 className='fw-bolder m-0'>Profile</h3>
                        </div>
                    </div>

                    <div id='kt_account_profile_details' className='collapse show'>
                        <form onSubmit={formik.handleSubmit} noValidate className='form'>
                            <div className='card-body border-top p-9'>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
                                    <div className='col-lg-3 col-md-6 col-sm-8'>
                                        {/* begin::Dropzone file */}
                                        {images.length === 0 && (
                                            <Dropzone
                                                accept={{ 'image/*': ['.jpeg', '.png'] }}
                                                maxFiles={1}
                                                maxSize={2000000}
                                                onDrop={(acceptedFiles, fileRejections) => dropFiles(acceptedFiles, fileRejections)}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <section className='w-100 mb-8' style={{ border: '3px solid #cdcdcc', borderStyle: 'dashed' }}>


                                                        <div {...getRootProps()} style={{ height: '250px' }}
                                                            className='d-flex flex-column w-100 justify-content-center align-items-center'>
                                                            <input {...getInputProps()} />
                                                            <p>
                                                                <span className="fa-4x text-secondary"><RiImageAddFill /></span>
                                                            </p>
                                                            <p className="p-4 text-center">
                                                                Only *.png, *.jpg and *.jpeg image <br />files are accepted <br />
                                                                The recommended ratio for avatar images is 1:1.
                                                            </p>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        )}

                                        {imagesError.length > 0 && (
                                            <div className='fv-plugins-message-container mb-3'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{imagesError}</span>
                                                </div>
                                            </div>
                                        )}

                                        {images.length > 0 && (
                                            <div id="thumnail-img" className='row mb-3'>
                                                {renderThumbnails}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Company Name</label>

                                    <div className='col-lg-8'>
                                        <div className='row'>
                                            <div className='col-lg-6 fv-row'>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg  mb-3 mb-lg-0'
                                                    placeholder='Company Name'
                                                    {...formik.getFieldProps('companyName')}
                                                />
                                                {formik.touched.companyName && formik.errors.companyName && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.companyName}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Country</label>

                                    <div className='col-lg-8'>
                                        <div className='row'>
                                            <div className='col-lg-6 fv-row'>
                                                <Select
                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            fontSize: '11.5pt',
                                                            paddingLeft: '8px'
                                                        }),
                                                    }}
                                                    placeholder="Select Country"
                                                    options={locationOptions}
                                                    isClearable={true}
                                                    {...formik.getFieldProps('location')}
                                                    onChange={handleChangeLocation}
                                                />
                                                {formik.touched.location && formik.errors.location && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.location}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Contact Person</label>

                                    <div className='col-lg-8'>
                                        <div className='row'>
                                            <div className='col-lg-6 fv-row'>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg  mb-3 mb-lg-0'
                                                    placeholder='Contact Person'
                                                    {...formik.getFieldProps('contactPerson')}
                                                />
                                                {formik.touched.contactPerson && formik.errors.contactPerson && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.contactPerson}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Contact Number</label>

                                    <div className='col-lg-8'>
                                        <div className='row'>
                                            <div className='col-lg-6 fv-row'>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg  mb-3 mb-lg-0'
                                                    placeholder='Contact Number'
                                                    {...formik.getFieldProps('contactNumber')}
                                                />
                                                {formik.touched.contactNumber && formik.errors.contactNumber && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.contactNumber}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Company Website</label>

                                    <div className='col-lg-8'>
                                        <div className='row'>
                                            <div className='col-lg-6 fv-row'>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg  mb-3 mb-lg-0'
                                                    placeholder='Company Website'
                                                    {...formik.getFieldProps('companyWebsite')}
                                                />
                                                {formik.touched.companyWebsite && formik.errors.companyWebsite && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.companyWebsite}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Customer Type</label>

                                    <div className='col-lg-8'>
                                        <div className='row'>
                                            <div className='col-lg-6 fv-row'>
                                                <Select
                                                    placeholder="Select Customer Type"
                                                    {...formik.getFieldProps('customerType')}
                                                    options={customerOptions}
                                                    isClearable={true}
                                                    onChange={handleChangeType}
                                                />
                                                {formik.touched.customerType && formik.errors.customerType && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.customerType}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold required fs-6'>Description</label>

                                    <div className='col-lg-8'>
                                        <div className='row'>
                                            <div className='col-lg-6 fv-row'>

                                                <textarea className="form-control" placeholder="Description" {...formik.getFieldProps('description')} rows={3} style={{ resize: 'none' }}></textarea>

                                                {/* <input
                                                    type='text'
                                                    className='form-control form-control-lg  mb-3 mb-lg-0'
                                                    placeholder='First name'
                                                    {...formik.getFieldProps('description')}
                                                /> */}
                                                {formik.touched.description && formik.errors.description && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.description}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                        {!loading && 'Save Changes'}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            <hr className="mt-2 mb-3" />
            <div className='card mb-5 mb-xl-5'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_signin_method'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Security</h3>
                    </div>
                </div>

                <div id='kt_account_signin_method' className='collapse show'>
                    <div className='card-body border-top p-9'>
                        <div className='d-flex flex-wrap align-items-center mb-10'>
                            <div id='kt_signin_password' className={' ' + (showPasswordForm && 'd-none')}>
                                <div className='fs-6 fw-bolder mb-1'>Password</div>
                                <div className='fw-bold text-gray-600'>************</div>
                            </div>

                            <div
                                id='kt_signin_password_edit'
                                className={'flex-row-fluid ' + (!showPasswordForm && 'd-none')}
                            >
                                <form
                                    onSubmit={formik2.handleSubmit}
                                    id='kt_signin_change_password'
                                    className='form'
                                    noValidate
                                >
                                    <div className='row mb-1'>
                                        <div className='col-lg-4'>
                                            <div className='fv-row mb-0'>
                                                <label htmlFor='currentpassword' className='form-label fs-6 fw-bolder mb-3'>
                                                    Current Password
                                                </label>
                                                <input
                                                    type='password'
                                                    className='form-control form-control-lg  '
                                                    id='currentpassword'
                                                    {...formik2.getFieldProps('currentPassword')}
                                                />
                                                {formik2.touched.currentPassword && formik2.errors.currentPassword && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik2.errors.currentPassword}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className='col-lg-4'>
                                            <div className='fv-row mb-0'>
                                                <label htmlFor='newpassword' className='form-label fs-6 fw-bolder mb-3'>
                                                    New Password
                                                </label>
                                                <input
                                                    type='password'
                                                    className='form-control form-control-lg  '
                                                    id='newpassword'
                                                    {...formik2.getFieldProps('newPassword')}
                                                />
                                                {formik2.touched.newPassword && formik2.errors.newPassword && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik2.errors.newPassword}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className='col-lg-4'>
                                            <div className='fv-row mb-0'>
                                                <label htmlFor='confirmpassword' className='form-label fs-6 fw-bolder mb-3'>
                                                    Confirm New Password
                                                </label>
                                                <input
                                                    type='password'
                                                    className='form-control form-control-lg  '
                                                    id='confirmpassword'
                                                    {...formik2.getFieldProps('passwordConfirmation')}
                                                />
                                                {formik2.touched.passwordConfirmation && formik2.errors.passwordConfirmation && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik2.errors.passwordConfirmation}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-text mb-5'>
                                        {(!formik2.errors.passwordConfirmation || !formik2.errors.newPassword || !formik2.errors.currentPassword) && (
                                            <div className='text-muted'>
                                                {/* Use 6 or more characters with a mix of letters, numbers & symbols. */}
                                                Use 3 or more characters
                                            </div>
                                        )}
                                    </div>

                                    <div className='d-flex'>
                                        <button
                                            id='kt_password_submit'
                                            type='submit'
                                            className='btn btn-primary me-2 px-6'
                                            disabled={loading2}
                                        >
                                            {!loading2 && 'Update Password'}
                                            {loading2 && (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                        <button
                                            onClick={() => {
                                                setPasswordForm(false);
                                                resetPasswordForm();
                                            }}
                                            id='kt_password_cancel'
                                            type='button'
                                            className='btn btn-color-gray-400 btn-active-light-primary px-6'
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <div
                                id='kt_signin_password_button'
                                className={'ms-auto ' + (showPasswordForm && 'd-none')}
                            >
                                <button
                                    onClick={() => {
                                        setPasswordForm(true)
                                    }}
                                    className='btn btn-light btn-active-light-primary'
                                >
                                    Reset Password
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const OverviewProfile: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Profile</PageTitle>
            <ProfilePage />
        </>
    )
}

export { OverviewProfile, ProfilePage };