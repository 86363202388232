/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle, usePageData } from '../../../_metronic/layout/core'
import {
  CardsWidget7,
  CardsWidget20,
} from '../../../_metronic/partials/widgets'
import SendingRequestDsb from '../../modules/auth/components/SendingRequestDsb'
import GettingRequestDsb from '../../modules/auth/components/GettingRequestDsb'
import { ScheduleWidgetDsb } from '../../../_metronic/partials/widgets/lists/ScheduleWidgetDsb'
import { getSummaryDashboard, getScheduleDashboard, postStatusMeeting } from '../../modules/auth/core/_requests'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


const DashboardPage: FC = () => {
  const MySwal = withReactContent(Swal)

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [requestType, setRequstType] = useState('send_request');
  const { isNewNotif, setIsNewNotif } = usePageData()
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (type) => {
    setShowModal(true);
    setRequstType(type);
  }

  const [showModalGet, setShowModalGet] = useState(false);
  const handleCloseModalGet = () => setShowModalGet(false);
  const handleShowModalGet = () => setShowModalGet(true);


  type ItemRequest = {
    id: number | null,
    company_name: string,
    company_type: string,
    contact_person: string,
    status_id?: number | null,
    status_label?: string | null,
    profile_image?: string
  }

  const [participants, setParticipants] = useState<{
    confirmed_meetings: Array<ItemRequest>,
    declined_request: Array<ItemRequest>,
    getting_request: Array<ItemRequest>,
    pending: Array<ItemRequest>,
    send_request: Array<ItemRequest>
  }>({
    confirmed_meetings: [],
    declined_request: [],
    getting_request: [],
    pending: [],
    send_request: []
  })

  const [summarry, setSummary] = useState<{
    confirmed_meetings: string,
    declined_request: string,
    getting_request: string,
    pending: string,
    send_request: string
  }>({
    confirmed_meetings: "",
    declined_request: "",
    getting_request: "",
    pending: "",
    send_request: ""
  })

  const [schedules, setSchedules] = useState<Array<{
    id: string,
    date: string,
    total: number,
    details: Array<{
      id: string
      time_start: string,
      time_end: string,
      request_type: string,
      by: string,
      created_at?: string
    }>
  }>>([{
    id: '',
    date: '',
    total: 0,
    details: [
      {
        id: '',
        time_start: '',
        time_end: '',
        request_type: '',
        by: ''
      }
    ]
  }]);

  function fetchSummary() {
    setLoading(true);
    const response = Promise.all([getSummaryDashboard(), getScheduleDashboard()])
      .then((response) => {
        setParticipants(response[0].data.details);
        // response[0].data.counts.getting_request = 2
        // response[0].data.counts.getting_request = 1
        setSummary(response[0].data.counts);
        setSchedules(response[1].data)
        setLoading(false);
      })
      .catch((errors) => {
        MySwal.fire({
          title: 'Error',
          html: "Can't fetch data",
          icon: 'error'
        })
        setLoading(false);
      })
  }

  useEffect(() => {
    fetchSummary()
    // setTimeout(() => {
    //   setParticipants({
    //     confirmed_meetings: [
    //       {
    //         id: 1,
    //         company_name: 'PT Confirmed 1',
    //         company_type: 'IT',
    //         contact_person: 'John Doe',
    //         status_label: 'send_request',
    //       },
    //       {
    //         id: 2,
    //         company_name: 'PT Confirmed 2',
    //         company_type: 'Automotive',
    //         contact_person: 'Peeter Doe',
    //         status_label: 'send_request',
    //       }
    //     ],
    //     declined_request: [
    //       {
    //         id: 1,
    //         company_name: 'PT Declined 1',
    //         company_type: 'IT',
    //         contact_person: 'John Doe',  
    //         status_label: 'send_request',
    //       },
    //       {
    //         id: 2,
    //         company_name: 'PT Declined 2',
    //         company_type: 'Automotive',
    //         contact_person: 'Peeter Doe',
    //         status_label: 'send_request',
    //       }
    //     ],
    //     getting_request: [
    //       {
    //         id: 1,
    //         company_name: 'PT Get 1',
    //         company_type: 'IT',
    //         contact_person: 'John Doe',
    //         status_label: 'send_request',
    //       },
    //       {
    //         id: 2,
    //         company_name: 'PT Get2',
    //         company_type: 'Automotive',
    //         contact_person: 'Peeter Doe',
    //         status_label: 'send_request',
    //       }
    //     ] ,
    //     pending: [

    //     ],
    //     send_request: [
    //       {
    //         id: 1,
    //         company_name: 'PT ABC',
    //         company_type: 'IT',
    //         contact_person: 'John Doe',
    //         status_label: 'send_request',
    //       },
    //       {
    //         id: 2,
    //         company_name: 'PT DEF',
    //         company_type: 'Automotive',
    //         contact_person: 'Peeter Doe',
    //         status_label: 'confirmed_meetings'
    //       },
    //       {
    //         id: 2,
    //         company_name: 'PT GHI',
    //         company_type: 'Automotive',
    //         contact_person: 'Peeter Doe',
    //         status_label: 'declined_request'
    //       }
    //     ]
    //   })

    // setSchedules([
    //   {
    //     id: '1',
    //     day: 'Monday',
    //     date: '2023-06-06 13:01:06',
    //     total: 2,
    //     details: [
    //       {
    //         id: '1',
    //         time_start: '10:30',
    //         time_end: '11:00',
    //         request_type: 'getting',
    //         by: 'John',
    //         created_at: '2023-06-06 13:01:06'
    //       },
    //       {
    //         id: '2',
    //         time_start: '11:00',
    //         time_end: '11:30',
    //         request_type: 'sending',
    //         by: 'Doe',
    //         created_at: '2023-06-06 13:01:06'
    //       }
    //     ]
    //   },
    //   {
    //     id: '2',
    //     day: 'Tuesday',
    //     date: '2023-06-07 13:01:06',
    //     total: 3,
    //     details: [
    //       {
    //         id: '3',
    //         time_start: '10:30',
    //         time_end: '11:00',
    //         request_type: 'getting',
    //         by: 'Uzumaki',
    //         created_at: '2023-06-06 13:01:06'
    //       },
    //       {
    //         id: '4',
    //         time_start: '11:00',
    //         time_end: '11:30',
    //         request_type: 'sending',
    //         by: 'Naruto',
    //         created_at: '2023-06-06 13:01:06'
    //       }
    //     ]
    //   },
    //   {
    //     id: '3',
    //     day: 'Wednesday',
    //     date: '2023-06-08 13:01:06',
    //     total: 4,
    //     details: [
    //       {
    //         id: '5',
    //         time_start: '10:30',
    //         time_end: '11:00',
    //         request_type: 'getting',
    //         by: 'Namikaze',
    //         created_at: '2023-06-06 13:01:06'
    //       },
    //       {
    //         id: '6',
    //         time_start: '11:00',
    //         time_end: '11:30',
    //         request_type: 'sending',
    //         by: 'Minato',
    //         created_at: '2023-06-06 13:01:06'
    //       }
    //     ]
    //   }
    // ])
    // }, 3000)
  }, [])

  useEffect(() => {
    if(isNewNotif) {
      fetchSummary()
    }
  }, [isNewNotif])

  const [loadingUpdate, setLoadingUpdate] = useState(false);

  async function updateRequest(detail_schedule_id, status_id) {
    let payload = {
      detail_schedule_id: detail_schedule_id,
      status_id: status_id
    }
    
    setLoadingUpdate(true);
    try {
      const response = await postStatusMeeting(payload)
      fetchSummary()

      if (response.data.status) {
        MySwal.fire({
          title: 'Success',
          html: response.data.message,
          icon: 'success'
        })
      } else {
        new Error("Error");
      }
    } catch (error) {
      MySwal.fire({
        title: 'Error',
        html: "error",
        icon: 'error'
      })
    } finally {
      setLoadingUpdate(false);
    }
  }

  return (
    <>
      {/* begin: Modal */}
      <SendingRequestDsb
        participants={participants}
        requestType={requestType}
        showModal={showModal}
        handleCloseModal={handleCloseModal} />

      <GettingRequestDsb
        participants={participants['getting_request']}
        showModalGet={showModalGet}
        loadingUpdate={loadingUpdate}
        handleUpdateRequest={updateRequest}
        handleCloseModalGet={handleCloseModalGet} />
      {/* end: Modal */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>

          <CardsWidget20
            className='h-md-50 mb-5 mb-xl-10'
            description='Received Request'
            color='#090655'
            labelColor='dark'
            img={''}
            isLoading={loading}
            summaryData={summarry}
            participantData={participants}
            onClickModal={handleShowModalGet}
            onClickRefresh={fetchSummary}
          />

          <CardsWidget7
            className='h-md-50 mb-5 mb-xl-10'
            description='Confirmed Request'
            icon={false}
            isLoading={loading}
            participants={participants.confirmed_meetings}
            stats={parseInt(summarry.confirmed_meetings || '0')}
            labelColor='light'
            textColor='gray-300'
            onClickModal={() => handleShowModal('confirmed_meetings')}
            onClickRefresh={fetchSummary}
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget7
            idCard='sendRequestCard'
            className='h-md-50 mb-5 mb-xl-10'
            description='Sent Request'
            icon={false}
            isLoading={loading}
            participants={participants.send_request}
            stats={parseInt(summarry.send_request || '0')}
            labelColor='light'
            textColor='gray-300'
            onClickModal={() => handleShowModal('send_request')}
            onClickRefresh={fetchSummary}
          />
          <CardsWidget7
            className='h-md-50 mb-5 mb-xl-10'
            description='Declined Request'
            icon={false}
            isLoading={loading}
            participants={participants.declined_request}
            stats={parseInt(summarry.declined_request || '0')}
            color='#F1416C'
            labelColor='dark'
            textColor='gray-300'
            onClickModal={() => handleShowModal('declined_request')}
            onClickRefresh={fetchSummary}
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6'>
          <ScheduleWidgetDsb schedules={schedules} className='card-xxl-stretch mb-xl-3' />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
