/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useRef } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { getLocation, register } from '../core/_requests'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useAuth } from '../core/Auth'
import Select from 'react-select'
import Dropzone from 'react-dropzone'
import { RiImageAddFill } from "react-icons/ri";
import { Toast } from 'react-bootstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const initialValues = {
  companyname: '',
  email: '',
  password: '',
  changepassword: '',
  location: '',
  contactPerson: '',
  contactNumber: '',
  companyWebsite: '',
  customerType: '',
  description: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  companyname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Company name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    // .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*?[~!@#$%^&*()_+|=\-\\{}[\];’:”<>?/,.])([\w\~!@#$%^&*()_+|=\-\\{}[\];’:”<>?/,.]{6,50})$/,
    //   'Use 6 or more characters with a mix of letters, numbers & symbols.')
    .min(3, 'Minimum 3 character')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    // .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  location: Yup.object().required('Location is required'),
  contactPerson: Yup.string().required('Contact person is required'),
  // contactNumber: Yup.string()
  //   .matches(/^([0-9]){4,15}$/, 'Must be number with length min.4 and max.15')
  //   .required('Contact number is required'),
  // companyWebsite: Yup.string(),
  // customerType: Yup.string().required('Customer type is required'),
  // description: Yup.string(),
  // acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration(props) {
  const MySwal = withReactContent(Swal)
  const [loading, setLoading] = useState(false)
  const [registerToast, setRegisterToast] = useState(false)
  const [registerResponse, setRegisterResponse] = useState({
    status: false,
    message: ''
  })
  const [locationOptions, setLocationOptions] = useState<Array<{
    label: string,
    options: Array<{}>
  }>>([
    {
      label: '',
      options: []
    }
  ])

  const navigate = useNavigate();
  const { saveAuth, setCurrentUser } = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const rawLocation = Object.assign({}, values.location)
      const payload = {
        name: values.companyname,
        password: values.password,
        c_password: values.changepassword,
        email: values.email,
        // location: rawLocation['value'] || 'Jakarta',
        // contact_number: values.contactNumber,
        contact_person: values.contactPerson,
        country_id: rawLocation['value']
        // company_type: values.customerType,
        // company_website: values.companyWebsite,
        // description: values.description,
      };

      const formData = new FormData();

      for (const img of images) {
        formData.append('images[]', img)
      }

      for (const p in payload) {
        formData.append(p, payload[p])
      }

      setLoading(true)
      try {
        const response = await register(formData)
        // setRegisterResponse({
        //   status: true,
        //   message: 'Registration successfully!'
        // })
        MySwal.fire({
          title: 'Success',
          html: 'Please check email for activation',
          icon: 'success'
        })

        setTimeout(() => {
          navigate("/auth/login")
        }, 2000);

      } catch (error: any) {
        const dt = error.response.data
        const hasError = dt.hasOwnProperty('errors');
        const attribute = Object.keys(dt.errors)

        let errorMsg = "The registration details is incorrect"
        if (hasError) errorMsg = attribute.length ? dt.errors[attribute[0]] : errorMsg

        // setRegisterResponse({
        //   status: false,
        //   message: 'Registration failed!'
        // })
        MySwal.fire({
          title: 'Error',
          html: errorMsg,
          icon: 'error'
        })
        saveAuth(undefined)
        setStatus(errorMsg)
        setSubmitting(false)
        setLoading(false)
      } finally {
        setRegisterToast(true);
        setLoading(false)
      }
    },
  })

  const [thumbnails, setThumbnails] = useState<any[]>([]);
  const [imagesError, setImagesError] = useState('');

  const [images, setImages] = useState<any[]>([]);

  const dropFiles = (acceptedFiles, fileRejections) => {
    setImagesError('');

    let mergedFile = [...images, ...acceptedFiles]
    if (mergedFile.length > 3) {
      setImagesError(`Too many files, maximal 3 file`)
      return;
    }
    setImages(mergedFile);

    let mapThumbnails = mergedFile.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }))
    setThumbnails(mapThumbnails);

    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          setImagesError(`File is too big, maximal size is 2 MB`);
        }

        if (err.code === "file-invalid-type") {
          setImagesError(`Invalid file type`);
        }

        if (err.code === 'too-many-files') {
          setImagesError(`Too many files, maximal 3 file`)
        }
      });
    });
  };

  const renderThumbnails = thumbnails.map((file: any) => (
    <div className='col-sm-3'
      key={file.name}
      style={{
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        padding: 4
      }}>
      <img
        src={file.preview}
        className="img-fluid img-thumbnail" alt={file.name}
        onLoad={() => { URL.revokeObjectURL(file.preview) }}></img>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => thumbnails.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  // useEffect(() => {
  //   if (formik.values.contactNumber) {
  //     let newValue = formik.values.contactNumber.replace(/\D/g, '')
  //     formik.setFieldValue('contactNumber', newValue)
  //   }

  //   if (formik.values.password) {
  //     const passwordRules = {
  //       character: /(?=.*[a-z])(?=.*[A-Z])/,
  //       symbol: /(?=.*?[~!@#$%^&*()_+|=\-\\{}[\];’:”<>?/,.])/,
  //       number: /(?=.*?[0-9])/,
  //       length: /^([\w\~!@#$%^&*()_+|=\-\\{}[\];’:”<>?/,.]{6,50})$/
  //     }

  //     let counter = 0;
  //     let barElm = document.getElementsByClassName('bar');


  //     for (let index = 0; index < barElm.length - 1; index++) {
  //       barElm[index].classList.remove('active')
  //     }

  //     for (const rule in passwordRules) {
  //       if (passwordRules[rule].test(formik.values.password)) {
  //         counter++;
  //       }

  //     }

  //     for (let index = 0; index <= counter - 1; index++) {
  //       barElm[index].classList.add('active')
  //     }
  //   }
  // }, [formik.values.password, formik.values.contactNumber])

  const changeLocation = (selected) => {
    formik.setTouched({ ...formik.touched, location: true });
    if (!selected) {
      formik.setErrors({ location: "Location is required" })
    }
    formik.setFieldValue('location', selected)
  }

  useEffect(() => {
    async function fetchLocation() {
      const response = await getLocation();
      setLocationOptions(response.data);
    }
    fetchLocation();
    // setLocationOptions([
    //   {
    //     label: "Group 1",
    //     options: [
    //       { label: "Group 1, option 1", value: "value_1" },
    //       { label: "Group 1, option 2", value: "value_2" }
    //     ]
    //   }
    // ])
  }, [])

  return (
    <>
      {/* <div style={{
        position: 'fixed',
        top: '15px',
        right: '15px'
      }}
      >
        <Toast delay={3000} show={registerToast} onClose={() => setRegisterToast(false)}>
          <div className={`fs-6 toast-header ${registerResponse.status ? 'bg-success' : 'bg-danger'} text-white`}>
            <img src="..." className="rounded me-2" alt="..."/>
            <strong className="me-auto">Information</strong>
            <button type="button" className="btn-close text-white" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
          <div className="toast-body fs-6">
            {registerResponse.message}
          </div>
        </Toast>
      </div> */}
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >

        {/* begin::Logo */}
        <div className='mb-10 text-center'>
          <img alt='Logo' src={toAbsoluteUrl('/ttc/logo.png')} className='h-90px' />
        </div>
        {/* end::Logo */}
        <div>
          {/* begin::Heading */}
          <div className='text-center mb-5'>
            {/* begin::Title */}
            <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
            {/* end::Title */}

            <div className='text-gray-500 fw-semibold fs-6'>Your Business Matching Forum</div>
          </div>
          {/* end::Heading */}

          {/* begin::Form group Email */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Email<span className='text-danger'>*</span></label>
            <input
              placeholder='Type here...'
              type='email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Password */}
          <div className='fv-row mb-8' data-kt-password-meter='true'>
            <div className='mb-1'>
              <label className='form-label fw-bolder text-dark fs-6'>Password<span className='text-danger'>*</span></label>
              <div className='position-relative mb-3'>
                <input
                  type='password'
                  placeholder='Type here...'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
              </div>
              {/* begin::Meter */}
              {/* <div
                className='d-flex align-items-center mb-3'
                data-kt-password-meter-control='highlight'
              >
                <div className={`flex-grow-1 bar bg-secondary bg-active-success rounded h-5px me-2`}></div>
                <div className={`flex-grow-1 bar bg-secondary bg-active-success rounded h-5px me-2`}></div>
                <div className={`flex-grow-1 bar bg-secondary bg-active-success rounded h-5px me-2`}></div>
                <div className={`flex-grow-1 bar bg-secondary bg-active-success rounded h-5px me-2`}></div>
              </div> */}
              {/* end::Meter */}
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
            {/* {!formik.errors.password && (
              <div className='text-muted'>
                Use 6 or more characters with a mix of letters, numbers & symbols.
              </div>
            )} */}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Confirm password */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>Confirm Password<span className='text-danger'>*</span></label>
            <input
              type='password'
              placeholder='Type here...'
              autoComplete='off'
              {...formik.getFieldProps('changepassword')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                },
                {
                  'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                }
              )}
            />
            {formik.touched.changepassword && formik.errors.changepassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.changepassword}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Companyname */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Company Name<span className='text-danger'>*</span></label>
            <input
              placeholder='Type here...'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('companyname')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.companyname && formik.errors.companyname,
                },
                {
                  'is-valid': formik.touched.companyname && !formik.errors.companyname,
                }
              )}
            />
            {formik.touched.companyname && formik.errors.companyname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.companyname}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group location */}
          {/* <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Location<span className='text-danger'>*</span></label>
            <Select
              placeholder="Please select..."
              options={locationOptions}
              isClearable={true}
              {...formik.getFieldProps('location')}
              onChange={changeLocation}
            />
            {formik.touched.location && formik.errors.location && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.location}</span>
                </div>
              </div>
            )}

          </div> */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Location<span className='text-danger'>*</span></label>
            <Select
              placeholder="Select Location..."
              options={locationOptions}
              isClearable={true}
              onChange={changeLocation}
            />
             {formik.touched.location && formik.errors.location && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.location}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group location */}

          {/* begin::Form group Contact */}
          <div className='row mb-8'>
            <div className='col-xl-12'>
              <label className='form-label fw-bolder text-dark fs-6'>Contact Person<span className='text-danger'>*</span></label>
              <input
                placeholder='Type here...'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('contactPerson')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.contactPerson && formik.errors.contactPerson,
                  },
                  {
                    'is-valid': formik.touched.contactPerson && !formik.errors.contactPerson,
                  }
                )}
              />
              {formik.touched.contactPerson && formik.errors.contactPerson && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.contactPerson}</span>
                  </div>
                </div>
              )}
            </div>
            {/* <div className='col-xl-6'>
              <label className='form-label fw-bolder text-dark fs-6'>Contact Number<span className='text-danger'>*</span></label>
              <input
                placeholder='Type here...'
                type='string'
                autoComplete='off'
                {...formik.getFieldProps('contactNumber')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.contactNumber && formik.errors.contactNumber,
                  },
                  {
                    'is-valid': formik.touched.contactNumber && !formik.errors.contactNumber,
                  }
                )}
              />
              {formik.touched.contactNumber && formik.errors.contactNumber && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.contactNumber}</span>
                  </div>
                </div>
              )}
            </div> */}
          </div>
          {/* end::Form group contact */}

          {/* begin::Form group Companyname */}
          {/* <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Company Website</label>
            <input
              placeholder='Type here...'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('companyWebsite')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.companyWebsite && formik.errors.companyWebsite,
                },
                {
                  'is-valid': formik.touched.companyWebsite && !formik.errors.companyWebsite,
                }
              )}
            />
            {formik.touched.companyWebsite && formik.errors.companyWebsite && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.companyWebsite}</span>
                </div>
              </div>
            )}
          </div> */}
          {/* end::Form group */}

          {/* begin::Form group Customer type */}
          {/* <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Customer Type<span className='text-danger'>*</span></label>
            <select
              aria-label="Select example"
              {...formik.getFieldProps('customerType')}
              className={clsx(
                'form-select bg-transparent',
                {
                  'is-invalid': formik.touched.customerType && formik.errors.customerType,
                },
                {
                  'is-valid': formik.touched.customerType && !formik.errors.customerType,
                }
              )}>
              <option value="">Please Select</option>
              <option value="1">Importers</option>
              <option value="2">Distributors</option>
              <option value="3">Suppliers</option>
            </select>
            {formik.touched.customerType && formik.errors.customerType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.customerType}</span>
                </div>
              </div>
            )}
          </div> */}
          {/* end::Form group customer type */}

          {/* begin::Form group Description */}
          {/* <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Description</label>
            <textarea
              rows={3}
              {...formik.getFieldProps('description')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.description && formik.errors.description,
                },
                {
                  'is-valid': formik.touched.description && !formik.errors.description,
                }
              )}>
            </textarea>
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.description}</span>
                </div>
              </div>
            )}
          </div> */}
          {/* end::Form group Description */}

          {/* begin::Dropzone file */}
          {/* <Dropzone
            accept={{ 'image/*': ['.jpeg', '.png'] }}
            maxFiles={3}
            maxSize={200000}
            onDrop={(acceptedFiles, fileRejections) => dropFiles(acceptedFiles, fileRejections)}>
            {({ getRootProps, getInputProps }) => (
              <section className='w-100 mb-8' style={{ border: '3px solid #cdcdcc', borderStyle: 'dashed' }}>
                <div {...getRootProps()} style={{ height: '250px' }}
                  className='d-flex flex-column w-100 justify-content-center align-items-center'>
                  <input {...getInputProps()} />
                  <p>
                    <span className="fa-4x text-secondary"><RiImageAddFill /></span>
                  </p>
                  <p>
                    Max file size is 2MB and max number of files is 3.<br />
                    Only *.png, *.jpg and *.jpeg image files are accepted
                  </p>
                  <p className="fst-italic ps-10 pe-10 text-center">
                    *) If you want to add more product photo, you can access it in the product menu
                  </p>
                </div>
              </section>
            )}
          </Dropzone>
          {imagesError.length > 0 && (
            <div className='fv-plugins-message-container mb-3'>
              <div className='fv-help-block'>
                <span role='alert'>{imagesError}</span>
              </div>
            </div>
          )}
          <div id="thumnail-img" className='row mb-3'>
            {renderThumbnails}
          </div> */}
          {/* end::Dropzone file */}
        </div>
        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          Already have an Account? &nbsp;
          <Link to='/auth/login'>
            <span className='font-semibold'>Sign in</span>
          </Link>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
